import { EndpointsConstant } from './endpoint.constant';

export class CountryEndpointsConstant {
  static countriesBase = 'countries';
  static countriesItemBase = `${CountryEndpointsConstant.countriesBase}/${EndpointsConstant.genericIdParamSelector}`;
  static config = {
    LIST: {
      url: CountryEndpointsConstant.countriesBase
    },
    DETAILS: {
      url: CountryEndpointsConstant.countriesItemBase
    }
  };
}
