import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  DistributorGroupSection,
  DistributorManagerType,
  DistributorSection
} from '@pages/distributor/enums/distributor-manager.enum';
import { debounceTime, merge, Observable } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { BackendService } from '@services/backend.service';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { NextInputValidationKey } from '@utils/enums/validators.enum';
import { NextFormsConstant } from '@constants/forms.constant';
import { SecuritiesAdminService } from '@pages/distributor/components/securities-form/securities-admin.service';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { DistributorGroupService } from '@pages/distributor/services/distributor-group.service';
import { DistributorGroupApi } from '@pages/distributor/types/distributor-group.type';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormItem } from '@utils/models/Form';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { CreditType } from '@pages/distributor/types/credit.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { CurrencyService } from '@pages/general-maintenance/pages/currency/services/currency.service';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { SecurityType } from '@pages/distributor/enums/securities.enum';

@Component({
  selector: 'distributor-securities-credit-limits',
  templateUrl: './distributor-securities-credit-limits.component.html',
  styleUrl: './distributor-securities-credit-limits.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorSecuritiesCreditLimitsComponent extends GenericBaseComponent implements OnInit {
  @Input() id: any;
  @Input() formGroup: FormGroup;
  @Input() distributorManagerType: DistributorManagerType;

  distributorGroupList$: Observable<DropdownList>;
  groupData: DistributorGroupApi;
  currencyList$: Observable<DropdownList>;
  creditLimitStatus$: Observable<DropdownList>;

  LABEL_KEY_PREFIX: string;

  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly NextIcon = NextIcon;
  protected readonly DistributorManagerType = DistributorManagerType;
  protected readonly InputNumberType = InputNumberType;
  protected readonly NextInputValidationKey = NextInputValidationKey;
  protected readonly CreditProps = CreditProps;
  protected readonly SCLProps = SCLProps;
  protected readonly DistributorGroupSection = DistributorGroupSection;
  protected readonly NextFormatCurrencyPipe = NextFormatCurrencyPipe;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;
  protected readonly DistributorGroupProps = DistributorGroupProps;

  constructor(
    private _dropdownService: DropdownService,
    private _backendService: BackendService,
    private _securitiesAdminService: SecuritiesAdminService,
    private _distributorManagerService: DistributorManagerService,
    private _distributorGroupService: DistributorGroupService,
    private _currencyService: CurrencyService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this._initializeFormConfig();
    this._formChangeEvents();
  }

  getGroupData(groupId: any) {
    this._distributorGroupService.get(groupId).subscribe(response => {
      this.groupData = response;
      this._setCreditDates(
        NextObjectHelper.getPropertyFromObject(this.groupData, [
          this.distributorManagerType === DistributorManagerType.DISTRIBUTOR
            ? DistributorSection.SECURITIES_AND_CREDIT_LIMITS
            : DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
          SCLProps.CREDIT
        ])
      );
    });
  }

  private _initializeFormConfig() {
    this.LABEL_KEY_PREFIX = DistributorManagerType.DISTRIBUTOR
      ? 'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS'
      : 'DISTRIBUTOR.GROUP.SECURITIES_AND_CREDIT_LIMITS';

    this.distributorGroupList$ = this._distributorGroupService.getDropdownList();
    this.currencyList$ = this._currencyService.getDropdownList();
    this.creditLimitStatus$ = this._distributorManagerService.getCreditLimitStatusDropdownList();
  }

  private _formChangeEvents() {
    const creditLimitsControl = this.formGroup.get([SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL]);

    const valueChangesSub = merge(creditLimitsControl!.valueChanges)
      .pipe(debounceTime(NextFormsConstant.formValueChangesDebounceMS))
      .subscribe(() => {
        this.formGroup.get(SCLProps.SECURITIES)?.updateValueAndValidity();
      });
    this.subs.push(valueChangesSub);

    /* TODO
    const packApprovalDateControl = this.formGroup.get([SCLProps.CREDIT, CreditProps.PACK_APPROVAL_DATE]);
    const packApprovalChangeSubs = packApprovalDateControl!.valueChanges.subscribe(() => {
      const securitiesFormArrayControl = this.formGroup.get(SCLProps.SECURITIES) as FormArray;
      securitiesFormArrayControl.controls.forEach(control => {
        control.get(GuarantorProps.EFFECTIVE_DATE)?.updateValueAndValidity();
      });
    });

    this.subs.push(packApprovalChangeSubs);
    */

    // Belongs To Group Changes
    if (this.distributorManagerType === DistributorManagerType.DISTRIBUTOR) {
      const belongsToGroupControl = this.formGroup.get([SCLProps.BELONGS_TO_GROUP]);
      const belongsToGroupValueChangesSub = merge(belongsToGroupControl!.valueChanges).subscribe(
        (belongsToGroup: boolean) => {
          const controlList = [CreditProps.EFFECTIVE_DATE, CreditProps.EXPIRY_DATE, CreditProps.PACK_APPROVAL_DATE];
          controlList.forEach((controlKey: any) => {
            const control: FormItem = this.formGroup.get([SCLProps.CREDIT, controlKey]) as FormItem;
            NextFormHelper.updateControlEnableStatus(control, !belongsToGroup);
            NextFormHelper.updateControlRequiredValidator(control, !belongsToGroup);
          });
          NextFormHelper.updateControlRequiredValidator(
            this.formGroup.get([SCLProps.GROUP, DistributorGroupProps.ID])!,
            belongsToGroup
          );
          if (!belongsToGroup) {
            this.formGroup.get([SCLProps.GROUP, DistributorGroupProps.ID])?.setValue(undefined);
          }
        }
      );
      this.subs.push(belongsToGroupValueChangesSub);

      // Group Changes
      const groupControl = this.formGroup.get([SCLProps.GROUP, DistributorGroupProps.ID]);
      const groupValueChangesSub = merge(groupControl!.valueChanges).subscribe((groupId: number) => {
        if (groupId) {
          this.getGroupData(groupId);
        } else {
          this._setCreditDates();
        }
      });
      this.subs.push(groupValueChangesSub);
    }
  }

  private _setCreditDates(credit: CreditType = {} as CreditType) {
    const creditGroup = this.formGroup.get([SCLProps.CREDIT]) as AbstractControl;
    creditGroup
      .get([CreditProps.EFFECTIVE_DATE])
      ?.setValue(NextValueHelper.defaultValue(credit[CreditProps.EFFECTIVE_DATE]));
    creditGroup
      .get([CreditProps.PACK_APPROVAL_DATE])
      ?.setValue(NextValueHelper.defaultValue(credit[CreditProps.PACK_APPROVAL_DATE]));
    creditGroup.get([CreditProps.EXPIRY_DATE])?.setValue(NextValueHelper.defaultValue(credit[CreditProps.EXPIRY_DATE]));
  }

  protected readonly SecurityType = SecurityType;
}
