<ng-container *ngIf="this.details$ | async as groupDetails">
  <next-action-header
    [label]="
      groupDetails
        | safeGuardObjectProperty
          : [DistributorApiProps.GROUP, DistributorGroupSection.GENERAL_INFORMATION, DistributorGroupProps.NAME]
    "
  >
    <ng-container *ngIf="isTaskBoard" actionContent>
      <review-modified-fields
        [updateData]="groupDetails | safeGuardObjectProperty: [DistributorApiProps.GROUP]"
        [originalData]="groupDetails | safeGuardObjectProperty: [TbDistributorApiProps.GROUP_REF]"
        [dataMapper]="DistributorGroupConstant.compareDataMapper"
        [section]="section"
      ></review-modified-fields>
    </ng-container>
  </next-action-header>

  <general-details
    [data]="groupDetails | safeGuardObjectProperty: [DistributorApiProps.GROUP]"
    [dataRef]="groupDetails | safeGuardObjectProperty: [TbDistributorApiProps.GROUP_REF]"
    [header]="headerDistributor | translate: { id: id }"
    [dataTitleConfig]="DistributorManagerConstant.distributorGroupGeneralDetails"
    [status]="
      groupDetails
        | safeGuardObjectProperty
          : [DistributorApiProps.GROUP, DistributorGroupSection.GENERAL_INFORMATION, DistributorGroupProps.STATUS]
    "
  ></general-details>

  <p-tabView [activeIndex]="sectionList.indexOf(activeSectionSig())" (activeIndexChange)="handleChangeTab($event)">
    <p-tabPanel [header]="TITLE_TABS_PREFIX + '.GENERAL_INFORMATION.NAME' | translate">
      <distributor-details-general-information
        [data]="
          groupDetails
            | safeGuardObjectProperty: [DistributorApiProps.GROUP, DistributorGroupSection.GENERAL_INFORMATION]
        "
        [dataRef]="
          groupDetails
            | safeGuardObjectProperty: [TbDistributorApiProps.GROUP_REF, DistributorGroupSection.GENERAL_INFORMATION]
        "
        [isGroup]="isGroup"
      ></distributor-details-general-information>
    </p-tabPanel>
    <p-tabPanel [header]="TITLE_TABS_PREFIX + '.SECURITIES_AND_CREDIT_LIMITS.NAME_GROUP' | translate">
      <distributor-details-securities-credit-limits
        [data]="
          groupDetails
            | safeGuardObjectProperty: [DistributorApiProps.GROUP, DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]
        "
        [dataRef]="
          groupDetails
            | safeGuardObjectProperty
              : [TbDistributorApiProps.GROUP_REF, DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]
        "
        [isGroup]="isGroup"
      ></distributor-details-securities-credit-limits>
    </p-tabPanel>
    <p-tabPanel [header]="TITLE_TABS_PREFIX + '.DISTRIBUTOR_CREDIT_LIMITS.NAME' | translate">
      <distributor-credit-limits
        [groupCreditLimits]="
          groupDetails
            | safeGuardObjectProperty: [DistributorApiProps.GROUP, DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]
        "
        [distributorCreditLimits]="
          groupDetails
            | safeGuardObjectProperty: [DistributorApiProps.GROUP, DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]
        "
      ></distributor-credit-limits>
    </p-tabPanel>
  </p-tabView>
</ng-container>
