import { Injectable } from '@angular/core';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfig } from '@utils/models/Form';
import { FormBuilder } from '@angular/forms';
import { NextInputValidators } from '../../../../shared/validators/input.validator';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { DistributorGroupSection } from '@pages/distributor/enums/distributor-manager.enum';
import { TimeCompareReference } from '../../../../core/enums/date.enum';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';
import { GuarantorType, InsurerType, SecuritiesType } from '@pages/distributor/types/securities.type';
import { NextValueHelper } from '@utils/core/next-value.helper';

@Injectable({
  providedIn: 'root'
})
export class SecuritiesAdminService {
  constructor(private _fb: FormBuilder) {}

  buildSecuritiesFormGroup(securities: SecuritiesType = {} as SecuritiesType) {
    const group = {
      [SecurityType.GUARANTORS]: this.buildGuarantorsFormArray(
        NextValueHelper.defaultValue(securities[SecurityType.GUARANTORS], [])
      ),
      [SecurityType.INSURERS]: this.buildInsurersFormArray(
        NextValueHelper.defaultValue(securities[SecurityType.INSURERS], [])
      )
    };
    return this._fb.group(group);
  }

  buildGuarantorsFormArray(guarantors: GuarantorType[]) {
    const groups = guarantors.map((guarantor: GuarantorType) => this.buildGuarantorsFormGroup(guarantor));
    return this._fb.array(groups);
  }
  buildInsurersFormArray(insurers: InsurerType[]) {
    const groups = insurers.map((insurer: InsurerType) => this.buildInsurerFormGroup(insurer));
    return this._fb.array(groups);
  }

  buildGuarantorsFormGroup(guarantor: GuarantorType = {} as GuarantorType) {
    const { props, config } = this._buildGuarantorConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, guarantor, config);
    return this._fb.group(formGroupState);
  }

  private _buildGuarantorConfigValues() {
    const groupProps = [
      GuarantorProps.TYPE,
      GuarantorProps.NAME,
      GuarantorProps.STATUS,
      GuarantorProps.CURRENCY,
      GuarantorProps.AMOUNT,
      GuarantorProps.EFFECTIVE_DATE,
      GuarantorProps.EXPIRY_DATE
    ];

    const formConfig: FormConfig = {
      [GuarantorProps.NAME]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [GuarantorProps.STATUS]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [GuarantorProps.CURRENCY]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [GuarantorProps.AMOUNT]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [GuarantorProps.EFFECTIVE_DATE]: {
        [FormConfigProps.IS_REQUIRED]: true,
        validators: [
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.BEFORE,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.PACK_APPROVAL_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.PACK_APPROVAL_DATE'
          )
        ]
      },
      [GuarantorProps.EXPIRY_DATE]: {
        [FormConfigProps.IS_REQUIRED]: true,
        validators: [
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.AFTER,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.EXPIRY_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.EXPIRY_DATE'
          ),
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.BEFORE,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.EFFECTIVE_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.EFFECTIVE_DATE'
          )
        ]
      },
      [InsurerProps.STANDARD_LIMIT]: {
        [FormConfigProps.IS_DISABLED]: true
      },
      [InsurerProps.EXCEPTION_LIMIT]: {
        [FormConfigProps.IS_DISABLED]: true
      }
    };

    return { props: groupProps, config: formConfig };
  }

  buildInsurerFormGroup(insurerType: InsurerType = {} as InsurerType) {
    const { props, config } = this._buildInsurerConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, insurerType, config);
    return this._fb.group(formGroupState);
  }

  private _buildInsurerConfigValues() {
    const groupProps = [
      InsurerProps.TYPE,
      InsurerProps.NAME,
      InsurerProps.STATUS,
      InsurerProps.CURRENCY,
      InsurerProps.AMOUNT,
      InsurerProps.EFFECTIVE_DATE,
      InsurerProps.EXPIRY_DATE
    ];

    const formConfig: FormConfig = {
      [InsurerProps.NAME]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [InsurerProps.STATUS]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [InsurerProps.CURRENCY]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [InsurerProps.AMOUNT]: {
        [FormConfigProps.IS_REQUIRED]: true
      },
      [InsurerProps.EFFECTIVE_DATE]: {
        [FormConfigProps.IS_REQUIRED]: true,
        validators: [
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.BEFORE,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.PACK_APPROVAL_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.PACK_APPROVAL_DATE'
          )
        ]
      },
      [InsurerProps.EXPIRY_DATE]: {
        [FormConfigProps.IS_REQUIRED]: true,
        validators: [
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.AFTER,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.EXPIRY_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.EXPIRY_DATE'
          ),
          NextInputValidators.dateComparedToReferenceNotBe(
            TimeCompareReference.BEFORE,
            [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.EFFECTIVE_DATE],
            'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.EFFECTIVE_DATE'
          )
        ]
      },
      [InsurerProps.STANDARD_LIMIT]: {
        [FormConfigProps.IS_DISABLED]: true
      },
      [InsurerProps.EXCEPTION_LIMIT]: {
        [FormConfigProps.IS_DISABLED]: true
      }
    };

    return { props: groupProps, config: formConfig };
  }
}
