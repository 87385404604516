import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DistributorManagerType, DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { SecuritiesConstant } from '@pages/distributor/components/securities-form/securities.constant';
import { FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { CurrencyEndpointsConstant } from '../../../../core/constants/currency.endpoints.constant';
import { FormDataConstant } from '@components/atoms/form-inputs/constants/form-data.constant';
import { SecurityEntity } from '@pages/distributor/components/securities-form/securities.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { SecuritiesAdminService } from '@pages/distributor/components/securities-form/securities-admin.service';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { DeepControlValuePipe } from '@pipes/common/deep-control-value.pipe';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';
import { BankService } from '@pages/distributor/services/bank.service';
import { BankType } from '@pages/general-maintenance/pages/bank-security/enums/bank-security.enum';

@Component({
  selector: 'securities-form',
  templateUrl: './securities-form.component.html',
  styleUrl: './securities-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecuritiesFormComponent implements OnInit {
  @Input() formArray: FormArray;
  @Input() securityType: SecurityType;
  @Input() distributorManagerType: DistributorManagerType;
  @Input() creditLimit: number;

  entityTypeList: DropdownList;
  securityStatusList$: Observable<DropdownList>;

  securityBankList$: Observable<DropdownList>;
  securityCurrencyList$: Observable<DropdownList>;
  riskDebtorList$: Observable<DropdownList>;
  insuranceCountryRiskList$: Observable<DropdownList>;

  protected readonly DistributorProps = DistributorProps;
  protected readonly NextIcon = NextIcon;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly InputNumberType = InputNumberType;
  protected readonly DeepControlValuePipe = DeepControlValuePipe;
  protected readonly SecurityEntity = SecurityEntity;
  protected readonly GuarantorProps = GuarantorProps;
  protected readonly SecurityType = SecurityType;
  protected readonly InsurerProps = InsurerProps;

  constructor(
    private _dropdownService: DropdownService,
    private _securitiesAdminService: SecuritiesAdminService,
    private _distributorManagerService: DistributorManagerService,
    private _bankService: BankService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
  }

  handleAddSecurity() {
    const securityFormGroup =
      this.securityType === SecurityType.INSURERS
        ? this._securitiesAdminService.buildInsurerFormGroup()
        : this._securitiesAdminService.buildGuarantorsFormGroup();
    this.formArray.push(securityFormGroup);
  }

  handleRemoveSecurity(index: number) {
    this.formArray.removeAt(index);
  }

  private _initializeFormConfig() {
    this.securityCurrencyList$ = this._dropdownService.getDropdownTranslationList(
      CurrencyEndpointsConstant.config.LIST,
      {
        ...FormDataConstant.dropdownBaseConfig
      }
    );

    this.entityTypeList = this._dropdownService.buildDropdownGenericTranslationList(
      this.securityType === SecurityType.INSURERS
        ? SecuritiesConstant.insurerTypeList
        : SecuritiesConstant.guarantorTypeList,
      {
        labelMapping: SecuritiesConstant.securityEntityLabelMapping,
        hasTranslation: true
      }
    );

    this.securityStatusList$ = this._distributorManagerService.getSecurityStatusDropdownList();

    this.securityBankList$ = this._bankService.getDropdownList(BankType.SECURITY);
    this.riskDebtorList$ = this._dropdownService.getDropdownTranslationList(CurrencyEndpointsConstant.config.LIST, {
      ...FormDataConstant.dropdownBaseConfig
    });
    this.insuranceCountryRiskList$ = this._dropdownService.getDropdownTranslationList(
      CurrencyEndpointsConstant.config.LIST,
      {
        ...FormDataConstant.dropdownBaseConfig
      }
    );
  }
}
