import { Injectable } from '@angular/core';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { BankEndpointsConstant } from '@constants/bank.endpoints.constant';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { BankType } from '@pages/general-maintenance/pages/bank-security/enums/bank-security.enum';
import { GenericObject } from '@utils/models/Types';

@Injectable({
  providedIn: 'root'
})
export class BankService {
  constructor(private _dropdownService: DropdownService) {}

  getDropdownList(type?: BankType) {
    const params: GenericObject = {};
    if (type) {
      params['type'] = type;
    }
    return this._dropdownService.getDropdownTranslationList(
      BankEndpointsConstant.config.MAIN,
      {
        valueProp: [FundingBankProps.BANK],
        labelProp: [FundingBankProps.NAME]
      },
      { resultWithinProp: ['result', 'banks'], params }
    );
  }
}
