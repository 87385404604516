import { genericDefaultItemProp } from '@components/atoms/form-inputs/components/next-dropdown/enums/dropdown.enum';

export enum FormDataConfigQueryParams {
  FIELD_ID = 'formFieldName'
}

export enum FormDataConfigProps {
  ENTRIES = 'entries',
  ENTRY_LABEL = 'label',
  ENTRY_VALUE = 'value',
  BY_DEFAULT = genericDefaultItemProp
}
