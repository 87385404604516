import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { DistributorGroupSection } from '@pages/distributor/enums/distributor-manager.enum';
import { Observable } from 'rxjs';
import { DistributorGroupApi } from '@pages/distributor/types/distributor-group.type';
import { DistributorManagerEndpoints } from '@pages/distributor/constant/distributor-manager.endpoints.constant';
import { RouteParams } from '../../../core/enums/route.enum';
import { ApiProps } from '../../../core/enums/api.enum';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';

@Injectable({
  providedIn: 'root'
})
export class DistributorGroupService {
  constructor(
    private _backendService: BackendService,
    private _dropdownService: DropdownService
  ) {}

  saveSection(isFirst: boolean, section: DistributorGroupSection, body = {}) {
    const endpointItem = DistributorGroupConstant.endpointItemBySection[section];
    if (isFirst) {
      return this._backendService.post(endpointItem, body);
    } else {
      return this._backendService.put(endpointItem, body);
    }
  }

  get(id: number): Observable<DistributorGroupApi> {
    return this._backendService.get(DistributorManagerEndpoints.config.GROUP.MAIN, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT]
    });
  }

  getDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.GROUP.LIST,
      {
        labelProp: [DistributorGroupSection.GENERAL_INFORMATION, DistributorGroupProps.NAME],
        valueProp: [DistributorGroupSection.GENERAL_INFORMATION, DistributorGroupProps.ID]
      },
      { resultWithinProp: ['result', 'groups'] }
    );
  }
}
