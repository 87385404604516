<steps-form-generic *ngIf="this.activeSectionSig"
                    [titleKey]="computedFormSectionConfig | safeGuardObjectProperty: [this.activeSectionSig(), FormSectionProps.LABEL_KEY]"
                    [formSectionList]="formSectionList"
                    [activeSection]="activeSectionSig()"
                    [formSectionConfig]="computedFormSectionConfig"
                    [isDisabledBackButton]="false"
                    [isDisabledNextButton]="!activeFormControl!.valid"
                    [isDisabledSaveButton]="activeFormControl!.pristine || !activeFormControl!.valid"
                    [isDisabledFinishButton]="formGroup.invalid"
                    (changeSection)="changeSection($event)"
                    (nextClick)="handleNextButton()"
                    (cancelClick)="handleCancelButton()"
                    (saveClick)="handleSave()"
                    (backClick)="handleBackButton()">
  <ng-container [ngSwitch]="activeSectionSig()">
    <group-general-information *ngSwitchCase="DistributorGroupSection.GENERAL_INFORMATION"
                               [formGroup]="formGroup | deepControl: [DistributorGroupSection.GENERAL_INFORMATION]">
    </group-general-information>
    <distributor-securities-credit-limits *ngSwitchCase="DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS"
                                          [distributorManagerType]="DistributorManagerType.GROUP"
                                          [formGroup]="formGroup | deepControl: [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]">
    </distributor-securities-credit-limits>
    <ng-container *ngSwitchCase="DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS">
      <distributor-credit-limits
        *ngIf="formGroup | deepControlValue: [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS] | async as groupCreditLimits"
        [formArray]="formGroup | deepControl: [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]"
        [groupCreditLimits]="groupCreditLimits">
      </distributor-credit-limits>
    </ng-container>
  </ng-container>
</steps-form-generic>
