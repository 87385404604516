<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.ID] as distributorCodeControl"
    [control]="distributorCodeControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.CODE'"
    slot1
  >
    <next-input [control]="distributorCodeControl" [testIdDataAttr]="DistributorProps.ID" inputElement></next-input>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.ACCOUNT_STATUS] as accountStatusControl"
    [control]="accountStatusControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.ACCOUNT_STATUS'"
    slot2
  >
    <next-dropdown
      [control]="accountStatusControl"
      [itemsObs$]="accountStatus$"
      [testIdDataAttr]="DistributorProps.ACCOUNT_STATUS"
      inputElement
    ></next-dropdown>
  </input-container>
</column-pair>

<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.NAME] as distributorNameControl"
    [control]="distributorNameControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.NAME'"
    slot1
  >
    <next-input
      [control]="distributorNameControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.NAME]"
      [testIdDataAttr]="DistributorProps.NAME"
      inputElement
    ></next-input>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.OPERATION_STATUS] as operationStatusControl"
    [control]="operationStatusControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.OPERATION_STATUS'"
    slot2
  >
    <next-dropdown
      [control]="operationStatusControl"
      [itemsObs$]="operationStatus$"
      [testIdDataAttr]="DistributorProps.OPERATION_STATUS"
      inputElement
    ></next-dropdown>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.ID_NUMBER] as idNumberControl"
    [control]="idNumberControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.ID_NUMBER'"
    slot1
  >
    <next-input
      [control]="idNumberControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.ID_NUMBER]"
      [testIdDataAttr]="DistributorProps.ID_NUMBER"
      inputElement
    ></next-input>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.RISK_STATUS] as riskStatusControl"
    [control]="riskStatusControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.RISK_CLASS'"
    slot2
  >
    <next-dropdown
      [control]="riskStatusControl"
      [itemsObs$]="riskStatus$"
      [testIdDataAttr]="DistributorProps.RISK_STATUS"
      inputElement
    ></next-dropdown>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.COUNTRY] as countryControl"
    [control]="countryControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.COUNTRY'"
    slot1
  >
    <next-dropdown
      [control]="countryControl"
      [itemsObs$]="countryList$"
      [testIdDataAttr]="DistributorProps.COUNTRY"
      inputElement
    ></next-dropdown>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.COUNTRY_MEMBERSHIP] as countryMembershipControl"
    [control]="countryMembershipControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.COUNTRY_MEMBERSHIP'"
    slot2
  >
    <next-input
      [control]="countryMembershipControl"
      [testIdDataAttr]="DistributorProps.COUNTRY_MEMBERSHIP"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.CREATION_DATE] as creationDateControl"
    [control]="creationDateControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.CREATION_DATE'"
    slot1
  >
    <next-calendar
      [control]="creationDateControl"
      [testIdDataAttr]="DistributorProps.CREATION_DATE"
      inputElement
    ></next-calendar>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.VAT_NUMBER] as vatNumberControl"
    [control]="vatNumberControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.VAT_NUMBER'"
    slot1
  >
    <next-input
      [control]="vatNumberControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.VAT_NUMBER]"
      [testIdDataAttr]="DistributorProps.VAT_NUMBER"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<p-divider></p-divider>

<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.COMPANY_ADDRESS] as companyAddressControl"
    [control]="companyAddressControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.COMPANY_ADDRESS'"
    slot1
  >
    <next-input
      [control]="companyAddressControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.COMPANY_ADDRESS]"
      [testIdDataAttr]="DistributorProps.COMPANY_ADDRESS"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.POSTAL_CODE] as postalCodeControl"
    [control]="postalCodeControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.POSTAL_CODE'"
    slot1
  >
    <next-input
      [control]="postalCodeControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.POSTAL_CODE]"
      [testIdDataAttr]="DistributorProps.POSTAL_CODE"
      inputElement
    ></next-input>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.TOWN] as townControl"
    [control]="townControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.TOWN'"
    slot2
  >
    <next-input
      [control]="townControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.TOWN]"
      [testIdDataAttr]="DistributorProps.TOWN"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.CONTACT_NAME] as contactNameControl"
    [control]="contactNameControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.CONTACT_NAME'"
    slot1
  >
    <next-input
      [control]="contactNameControl"
      [maxlength]="DistributorConstant.formMaxLength[DistributorProps.CONTACT_NAME]"
      [testIdDataAttr]="DistributorProps.CONTACT_NAME"
      inputElement
    ></next-input>
  </input-container>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.WORK_TELEPHONE] as workTelephoneControl"
    [control]="workTelephoneControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.WORK_TELEPHONE'"
    slot2
  >
    <next-input
      [control]="workTelephoneControl"
      [testIdDataAttr]="DistributorProps.WORK_TELEPHONE"
      inputElement
    ></next-input>
  </input-container>
</column-pair>
<p-divider></p-divider>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorProps.DELETION_SUSPENSION_ORDER] as deletionSuspensionOrderControl"
    [control]="deletionSuspensionOrderControl"
    [labelKey]="'DISTRIBUTOR.MANAGER.FIELDS.DELETION_SUSPENSION_ORDER'"
    slot1
  >
    <next-radio
      [isBinaryOptions]="true"
      [control]="deletionSuspensionOrderControl"
      [testIdDataAttr]="DistributorProps.DELETION_SUSPENSION_ORDER"
      inputElement
    ></next-radio>
  </input-container>
</column-pair>
