<div class="funding-bank">
  <ng-container *ngIf="formArray">
    <div class="funding-bank__container" *ngFor="let groupItem of formArray.controls; first as first; let idx = index">
      <column-pair>
        <input-container *ngIf="groupItem | deepControl: [FundingBankProps.CODE] as fundingBankCodeControl"
                         [control]="fundingBankCodeControl"
                         [labelKey]="('FUNDING_BANK.FUNDING_BANK')" slot1>
          <next-dropdown [itemsObs$]="fundingBankList$" [control]="fundingBankCodeControl" inputElement></next-dropdown>
        </input-container>
        <div class="funding-bank__remove" slot2>

          <link-button [iconType]="NextIcon.DELETE"
                       [isDisabled]="formArray.controls.length === 1"
                       (clickEvt)="handleRemoveFundingBank(idx)" ></link-button>
        </div>
      </column-pair>
      <next-card [hasBackgroundColor]="true">
        <column-pair>
          <input-container
            *ngIf="groupItem | deepControl: [FundingBankProps.FUNDING_BANK_AMOUNT] as fundingBankAmountControl"
            [control]="fundingBankAmountControl"
            [labelKey]="('FUNDING_BANK.FUNDING_BANK_AMOUNT')" slot1>
            <next-input-number [control]="fundingBankAmountControl" [type]="InputNumberType.AMOUNT"
                               (inputChange)="handleInputChange(idx, FundingBankProps.FUNDING_BANK_AMOUNT)"
                               inputElement></next-input-number>
          </input-container>
          <input-container
            *ngIf="groupItem | deepControl: [FundingBankProps.FUNDING_BANK_PERCENTAGE] as fundingBankPercentageControl"
            [control]="fundingBankPercentageControl"
            [labelKey]="('FUNDING_BANK.FUNDING_BANK_PERCENTAGE')" slot2>
            <next-input-number [control]="fundingBankPercentageControl" [type]="InputNumberType.AMOUNT"
                               (inputChange)="handleInputChange(idx, FundingBankProps.FUNDING_BANK_PERCENTAGE)"
                               inputElement></next-input-number>
          </input-container>
        </column-pair>
      </next-card>
    </div>
    <next-message
      *ngIf="(formArray?.errors! | safeGuardObjectProperty: [NextInputValidationKey.AMOUNT_NOT_MATCH]) as error"
      [messages]="[{severity: 'warn', summary: 'FORM.VALIDATION.AMOUNT_NOT_MATCH' | translate: {fieldName: error.fieldName | translate, referenceFieldName: error.referenceFieldName | translate}}]"></next-message>
  </ng-container>
  <div class="funding-bank__add-new">
    <link-button [label]="('FUNDING_BANK.ADD_FUNDING_BANK') | translate"
                 [iconType]="NextIcon.PLUS" [iconPosition]="LinkButtonPosition.LEFT"
                 (clickEvt)="handleAddFundingBank()"></link-button>
  </div>
</div>
