import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DistributorManagerFilterProps, DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { Observable } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { CountryService } from '../../../../core/services/country.service';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';

@Component({
  selector: 'distributor-general-information',
  templateUrl: './distributor-general-information.component.html',
  styleUrl: './distributor-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorGeneralInformationComponent implements OnInit {
  @Input() formGroup: FormGroup;

  riskStatus$: Observable<DropdownList>;
  accountStatus$: Observable<DropdownList>;
  countryList$: Observable<DropdownList>;
  operationStatus$: Observable<DropdownList>;

  protected readonly DistributorProps = DistributorProps;
  protected readonly DistributorManagerFilterProps = DistributorManagerFilterProps;
  protected readonly DistributorConstant = DistributorConstant;

  constructor(
    private _countryService: CountryService,
    private _distributorManagerService: DistributorManagerService
  ) {}

  ngOnInit(): void {
    this._initializeFormConfig();
  }

  private _initializeFormConfig() {
    this.operationStatus$ = this._distributorManagerService.getOperationalStatusDropdownList();
    this.accountStatus$ = this._distributorManagerService.getAccountStatusDropdownList();
    this.riskStatus$ = this._distributorManagerService.getRiskStatusDropdownList();
    this.countryList$ = this._countryService.getDropdownList();
  }
}
