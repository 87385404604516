import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { TabPageAbstractComponent } from '@components/organisms/tab-page-module/tab-page-abstract/tab-page-abstract.component';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { DistributorProps, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { forkJoin, map, Observable } from 'rxjs';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { TbDistributorApi } from '@pages/task-board/types/tb-distributors.type';
import { TbDistributorApiProps } from '@pages/task-board/enums/tb-distributors.enum';
import { DistributorApiProps } from '@pages/distributor/enums/distributor.enum';
import { Distributor, DistributorApi } from '@pages/distributor/types/distributor.type';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';

@Component({
  selector: 'distributor-details',
  templateUrl: './distributor-details.component.html',
  styleUrl: './distributor-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorDetailsComponent extends TabPageAbstractComponent<DistributorSection> implements OnInit {
  @Input() isTaskBoard = false;

  title: string = 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_DISTRIBUTOR.TITLE';
  headerDistributor: string = 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_DISTRIBUTOR.ID_DISTRIBUTOR';
  generalDetailsMap: DataTitleConfig[] = DistributorManagerConstant.distributorGeneralDetailsMap;
  isGroup: boolean = false;
  details$: Observable<DistributorApi | TbDistributorApi>;
  section = TaskBoardSection.DISTRIBUTORS;

  protected readonly TITLE_TABS_PREFIX = 'DISTRIBUTOR.MANAGER.DETAILS.TABS';
  protected readonly TbDistributorApiProps = TbDistributorApiProps;
  protected readonly DistributorApiProps = DistributorApiProps;
  protected readonly DistributorConstant = DistributorConstant;
  protected readonly DistributorSection = DistributorSection;
  protected readonly DistributorProps = DistributorProps;

  constructor(
    private $injector: Injector,
    private _distributorService: DistributorService,
    private _taskBoardService: TaskBoardService
  ) {
    super($injector);
  }

  ngOnInit(): void {
    this._setEntityConfig();
    if (this.isTaskBoard) {
      this.details$ = forkJoin({
        [DistributorApiProps.DISTRIBUTOR]: this._taskBoardService.getTask(this.id),
        [TbDistributorApiProps.DISTRIBUTOR_REF]: this._distributorService.get(this.id)
      }).pipe(
        map(result => {
          const tbDistributor = JSON.parse(result[DistributorApiProps.DISTRIBUTOR].jsonPayload) as Distributor;
          return {
            [DistributorApiProps.DISTRIBUTOR]: tbDistributor,
            [TbDistributorApiProps.DISTRIBUTOR_REF]: result[TbDistributorApiProps.DISTRIBUTOR_REF].distributor
          } as TbDistributorApi;
        })
      );
    } else {
      this.details$ = this._distributorService.get(this.id);
    }
    super.initialize();
  }

  private _setEntityConfig() {
    this.sectionList = DistributorConstant.formSectionList;
    this.sectionConfig = DistributorConstant.formSectionConfig;
  }
}
