@defer (when ready) {
  <steps-form-generic
    [titleKey]="
      computedFormSectionConfig | safeGuardObjectProperty: [this.activeSectionSig(), FormSectionProps.LABEL_KEY]
    "
    [formSectionList]="formSectionList"
    [activeSection]="activeSectionSig()"
    [formSectionConfig]="computedFormSectionConfig"
    [isDisabledBackButton]="false"
    [isDisabledNextButton]="!activeFormControl!.valid"
    [isDisabledSaveButton]="activeFormControl!.pristine || !activeFormControl!.valid"
    [isDisabledFinishButton]="formGroup.invalid"
    (changeSection)="changeSection($event)"
    (nextClick)="handleNextButton()"
    (cancelClick)="handleCancelButton()"
    (saveClick)="handleSaveButton()"
    (backClick)="handleBackButton()"
  >
    <ng-container [ngSwitch]="activeSectionSig()">
      <distributor-general-information
        *ngSwitchCase="DistributorSection.GENERAL_INFORMATION"
        [formGroup]="formGroup | deepControl: [DistributorSection.GENERAL_INFORMATION]"
      ></distributor-general-information>
      <distributor-securities-credit-limits
        *ngSwitchCase="DistributorSection.SECURITIES_AND_CREDIT_LIMITS"
        [id]="id"
        [distributorManagerType]="DistributorManagerType.DISTRIBUTOR"
        [formGroup]="formGroup | deepControl: [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]"
      ></distributor-securities-credit-limits>
      <product-lines-and-blankets
        *ngSwitchCase="DistributorSection.PRODUCT_LINES_AND_BLANKETS"
        [formGroup]="formGroup | deepControl: [DistributorSection.PRODUCT_LINES_AND_BLANKETS]"
      ></product-lines-and-blankets>
      <source-location-mapping
        *ngSwitchCase="DistributorSection.SOURCE_LOCATION_MAPPING"
        [formArray]="formGroup | deepControl: [DistributorSection.SOURCE_LOCATION_MAPPING]"
      >
      </source-location-mapping>

      <distributor-observations
        *ngSwitchCase="DistributorSection.OBSERVATIONS"
        [formGroup]="formGroup | deepControl: [DistributorSection.OBSERVATIONS]"
      >
      </distributor-observations>
    </ng-container>
  </steps-form-generic>
} @placeholder {
  ...loading
}
