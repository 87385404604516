import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { FormArray } from '@angular/forms';
import { DistributorManagerType } from '@pages/distributor/enums/distributor-manager.enum';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { debounceTime, Observable, Subject } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { FundingBankAdminService } from '@pages/distributor/services/funding-bank-admin.service';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { AppSettingsConstant } from '@constants/app-settings.constant';
import { NextInputValidationKey } from '@utils/enums/validators.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { BankService } from '@pages/distributor/services/bank.service';
import { BankType } from '@pages/general-maintenance/pages/bank-security/enums/bank-security.enum';

@Component({
  selector: 'funding-bank-form',
  templateUrl: './funding-bank-form.component.html',
  styleUrl: './funding-bank-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingBankFormComponent extends GenericBaseComponent implements OnInit, OnChanges {
  @Input() formArray: FormArray;
  @Input() creditLimit: number;
  @Input() distributorManagerType: DistributorManagerType;

  fundingBankList$: Observable<DropdownList>;

  protected readonly NextIcon = NextIcon;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly FundingBankProps = FundingBankProps;
  protected readonly InputNumberType = InputNumberType;
  protected readonly NextInputValidationKey = NextInputValidationKey;
  private _fundingBankChanges: Subject<any> = new Subject<any>();

  constructor(
    private _dropdownService: DropdownService,
    private _fundingBankAdminService: FundingBankAdminService,
    private _banks: BankService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['creditLimit'] && !changes['creditLimit'].isFirstChange()) {
      this._calculatePercentage();
    }
  }

  ngOnInit(): void {
    this._initializeFormConfig();
    this.subs.push(
      this._fundingBankChanges
        .pipe(debounceTime(AppSettingsConstant.formValueChangesDebounceMS))
        .subscribe(({ index, input }) => {
          const control = this.formArray.at(index).get(input);
          if (input === FundingBankProps.FUNDING_BANK_AMOUNT) {
            const calculatedValue = control?.value ? this._calculatePercentageFromFromAmount(control.value) : undefined;
            this.formArray.at(index).get(FundingBankProps.FUNDING_BANK_PERCENTAGE)?.setValue(calculatedValue);
          } else if (input === FundingBankProps.FUNDING_BANK_PERCENTAGE) {
            const calculatedValue = control?.value ? this._calculateAmountFromPercentage(control.value) : undefined;
            this.formArray.at(index).get(FundingBankProps.FUNDING_BANK_AMOUNT)?.setValue(calculatedValue);
          }
        })
    );
  }

  handleAddFundingBank() {
    const fundingBankFormGroup = this._fundingBankAdminService.buildFundingBankFormGroup();
    this.formArray.push(fundingBankFormGroup);
  }

  handleRemoveFundingBank(index: number) {
    this.formArray.removeAt(index);
  }

  handleInputChange(index: number, input: FundingBankProps) {
    this._fundingBankChanges.next({ index, input });
  }

  private _calculatePercentage() {
    this.formArray.controls.forEach(group => {
      const percentageControl = group.get(FundingBankProps.FUNDING_BANK_PERCENTAGE);
      const amountControl = group.get(FundingBankProps.FUNDING_BANK_AMOUNT);
      if (percentageControl?.value) {
        const calculatedValue = this._calculateAmountFromPercentage(percentageControl?.value);
        amountControl?.setValue(calculatedValue);
      }
    });
  }

  private _calculateAmountFromPercentage(percentage: number) {
    return (percentage * this.creditLimit) / 100;
  }

  private _calculatePercentageFromFromAmount(amount: number) {
    return (amount * 100) / this.creditLimit;
  }

  private _initializeFormConfig() {
    this.fundingBankList$ = this._banks.getDropdownList(BankType.FUNDING);
  }
}
