import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { FormGroup } from '@angular/forms';
import { DistributorManagerFilterProps } from '@pages/distributor/enums/distributor-manager.enum';
import { Observable } from 'rxjs';
import { DropdownList } from '@components/atoms/form-inputs/components/next-dropdown/types/dropdown.type';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { CountryService } from '../../../../../../../../core/services/country.service';

@Component({
  selector: 'group-general-information',
  templateUrl: './group-general-information.component.html',
  styleUrl: './group-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupGeneralInformationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  countryList$: Observable<DropdownList>;
  currentDate = new Date();
  protected readonly DistributorGroupProps = DistributorGroupProps;
  protected readonly DistributorManagerFilterProps = DistributorManagerFilterProps;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;

  constructor(private _countryService: CountryService) {}

  ngOnInit(): void {
    this._initializeFormConfig();
  }

  private _initializeFormConfig() {
    this.countryList$ = this._countryService.getDropdownList();
  }
}
