<div class="distributor-credit-limits">
  <next-card [headerKey]="(TITLE_SECURITIES_PREFIX + '.TITLES.GROUP_CREDIT_LIMITS')">
    <ng-container>
      <data-card
        [dataObject]="totalGroupCreditLimit | nextFormatCurrency: groupCurrency"
        [config]="{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.TOTAL_GROUP_CREDIT_LIMIT') }"
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
      <next-message
        *ngIf="this.isEdition && (formArray?.errors! | safeGuardObjectProperty: [NextInputValidationKey.AMOUNT_NOT_MATCH]) as error"
        [messages]="[{severity: 'warn', summary: 'DISTRIBUTOR.GROUP.DISTRIBUTOR_CREDIT_LIMITS.SECURITIES_TOTAL_WARN' | translate}]"></next-message>
    </ng-container>
  </next-card>
  <next-card [headerKey]="(TITLE_SECURITIES_PREFIX + '.TITLES.DISTRIBUTOR_CREDIT_LIMITS') | translate">
    <next-table
      [data]="distributorCreditLimitList"
      [columns]="DistributorManagerConstant.tableListDistributorCreditLimitsConfig"
    >
      <ng-template pTemplate="columnTemplateRef" let-index="index">
        <input-container
          *ngIf="formArrayDataCreditLimits | deepControl: [index, DistributorProps.CREDIT_LIMIT_AMOUNT] as creditLimitAmountControl"
          [control]="creditLimitAmountControl" class="group-details__input">
          <!-- TODO Change to distributor's Currency -->
          <next-input-number [control]="creditLimitAmountControl" [type]="InputNumberType.CURRENCY_AMOUNT"
                             [currency]="this.groupCurrency"
                             inputElement></next-input-number>
        </input-container>
      </ng-template>
    </next-table>
  </next-card>
</div>
