import { Injectable } from '@angular/core';
import { DistributorProps, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfig } from '@utils/models/Form';
import { FundingBankAdminService } from '@pages/distributor/services/funding-bank-admin.service';
import { Distributor, DSecuritiesCreditLimits } from '@pages/distributor/types/distributor.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { ProductLinesBlanketsAdminService } from '@pages/distributor/services/product-lines-blankets-admin.service';
import { SourceLocationMappingAdminService } from '@pages/distributor/services/source-location-mapping-admin.service';
import { NextInputValidators } from '../../../shared/validators/input.validator';
import { PatternConstant } from '../../../core/constants/pattern.constant';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { AddressProps } from '../../../core/enums/address.enum';
import { ContactProps } from '../../../core/enums/contact.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { GenericObject } from '@utils/models/Types';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { SecuritiesAdminService } from '@pages/distributor/components/securities-form/securities-admin.service';
import { CreditAdminService } from '@pages/distributor/services/credit-admin.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorAdminService {
  constructor(
    private _fb: FormBuilder,
    private _securitiesAdminService: SecuritiesAdminService,
    private _creditAdminService: CreditAdminService,
    private _fundingBankAdminService: FundingBankAdminService,
    private _productLinesBlanketsAdminService: ProductLinesBlanketsAdminService,
    private _sourceLocationMappingAdminService: SourceLocationMappingAdminService
  ) {}

  buildDistributorFormGroup(distributor?: Distributor, isEdit?: boolean): FormGroup {
    const mainGroupValues = {
      [DistributorSection.GENERAL_INFORMATION]: this._buildGeneralInformationGroup(
        NextObjectHelper.getPropertyFromObject(distributor, [DistributorSection.GENERAL_INFORMATION])
      ),
      [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: this._buildSecuritiesCreditLimitFormGroup(
        NextObjectHelper.getPropertyFromObject(distributor, [DistributorSection.SECURITIES_AND_CREDIT_LIMITS])
      ),
      [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: this._buildProductLinesAndBlanketsGroup(
        NextObjectHelper.getPropertyFromObject(distributor, [DistributorSection.PRODUCT_LINES_AND_BLANKETS]),
        isEdit
      ),
      [DistributorSection.SOURCE_LOCATION_MAPPING]:
        this._sourceLocationMappingAdminService.buildSourceLocationMappingFormArray(
          NextObjectHelper.getPropertyFromObject(distributor, [DistributorSection.SOURCE_LOCATION_MAPPING]),
          isEdit
        ),
      [DistributorSection.OBSERVATIONS]: this._buildObservationsGroup(
        NextObjectHelper.getPropertyFromObject(distributor, [DistributorSection.OBSERVATIONS])
      )
    };
    console.log('main froup values --', mainGroupValues)
    return this._fb.group(mainGroupValues);
  }

  getFormattedDataFromSection(formValues: any, section: DistributorSection) {
    const sectionCallbacks: GenericObject = {
      [DistributorSection.GENERAL_INFORMATION]: () => this.buildGeneralInformationFormValuesToAPIFormat(formValues),
      [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: () => formValues,
      [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: () => {
        return {};
      },
      [DistributorSection.SOURCE_LOCATION_MAPPING]: () => {
        return {};
      },
      [DistributorSection.OBSERVATIONS]: () => {
        return '';
      }
    };

    return sectionCallbacks[section]();
  }

  buildGeneralInformationFormValuesToAPIFormat(formValues: any = {}) {
    return {
      [DistributorProps.ADDRESS]: {
        [AddressProps.ADDRESS]: formValues[DistributorProps.COMPANY_ADDRESS],
        [AddressProps.TOWN]: formValues[DistributorProps.TOWN],
        [AddressProps.POSTAL_CODE]: formValues[DistributorProps.POSTAL_CODE]
      },
      [DistributorProps.DELETION_SUSPENSION_ORDER]: formValues[DistributorProps.DELETION_SUSPENSION_ORDER],
      [DistributorProps.CONTACT]: {
        [ContactProps.NAME]: formValues[DistributorProps.CONTACT_NAME],
        [ContactProps.PHONE]: formValues[DistributorProps.WORK_TELEPHONE]
      },
      [DistributorProps.COUNTRY]: {
        [CountryProps.CODE]: formValues[DistributorProps.COUNTRY]
      },
      [DistributorProps.CREATION_DATE]: formValues[DistributorProps.CREATION_DATE],
      [DistributorProps.ID_NUMBER]: formValues[DistributorProps.ID_NUMBER],
      [DistributorProps.RISK_STATUS]: formValues[DistributorProps.RISK_STATUS],
      [DistributorProps.ACCOUNT_STATUS]: formValues[DistributorProps.ACCOUNT_STATUS],
      [DistributorProps.NAME]: formValues[DistributorProps.NAME],
      [DistributorProps.OPERATION_STATUS]: formValues[DistributorProps.OPERATION_STATUS],
      [DistributorProps.VAT_NUMBER]: formValues[DistributorProps.VAT_NUMBER]
    };
  }

  private _buildGeneralInformationGroup(generalInfoValues = {}) {
    const { props, config } = this._buildGeneralInformationConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, generalInfoValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildGeneralInformationConfigValues() {
    const groupProps = [
      DistributorProps.ID,
      DistributorProps.NAME,
      DistributorProps.ACCOUNT_STATUS,
      DistributorProps.OPERATION_STATUS,
      DistributorProps.ID_NUMBER,
      DistributorProps.RISK_STATUS,
      DistributorProps.COUNTRY,
      DistributorProps.COUNTRY_MEMBERSHIP,
      DistributorProps.CREATION_DATE,
      DistributorProps.VAT_NUMBER,
      DistributorProps.COMPANY_ADDRESS,
      DistributorProps.POSTAL_CODE,
      DistributorProps.TOWN,
      DistributorProps.CONTACT_NAME,
      DistributorProps.WORK_TELEPHONE,
      DistributorProps.DELETION_SUSPENSION_ORDER
    ];

    const formConfig: FormConfig = {
      [DistributorProps.ID]: {
        isDisabled: true
      },
      [DistributorProps.NAME]: {
        isRequired: true
      },
      [DistributorProps.ACCOUNT_STATUS]: {
        isRequired: true
      },
      [DistributorProps.RISK_STATUS]: {
        isRequired: true
      },
      [DistributorProps.OPERATION_STATUS]: {
        isRequired: true
      },
      [DistributorProps.COUNTRY]: {
        [FormConfigProps.IS_REQUIRED]: true,
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.COUNTRY, CountryProps.CODE]
      },
      [DistributorProps.COUNTRY_MEMBERSHIP]: {
        isDisabled: true,
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.COUNTRY, CountryProps.MEMBERSHIP]
      },
      [DistributorProps.ID_NUMBER]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPattern)]
      },
      [DistributorProps.VAT_NUMBER]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPattern)]
      },

      [DistributorProps.COMPANY_ADDRESS]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.ADDRESS, AddressProps.ADDRESS]
      },
      [DistributorProps.POSTAL_CODE]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.ADDRESS, AddressProps.POSTAL_CODE]
      },
      [DistributorProps.TOWN]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpaces)],
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.ADDRESS, AddressProps.TOWN]
      },
      [DistributorProps.CONTACT_NAME]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.CONTACT, ContactProps.NAME]
      },
      [DistributorProps.WORK_TELEPHONE]: {
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorProps.CONTACT, ContactProps.PHONE]
      },
      [DistributorProps.DELETION_SUSPENSION_ORDER]: {
        isRequired: true
      },
      [DistributorProps.CREATION_DATE]: {
        isDisabled: false
      }
    };

    return { props: groupProps, config: formConfig };
  }

  private _buildSecuritiesCreditLimitFormGroup(
    securitiesCreditLimitsValues: DSecuritiesCreditLimits = {} as DSecuritiesCreditLimits
  ) {
    const { props, config } = this._buildSCLBaseValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, securitiesCreditLimitsValues, config);

    formGroupState[SCLProps.GROUP] = this._buildSCLGroupFormGroup(securitiesCreditLimitsValues);
    formGroupState[SCLProps.CREDIT] = this._creditAdminService.buildCreditLimitsFormGroup(securitiesCreditLimitsValues);
    formGroupState[SCLProps.CREDIT_BANKS] = this._fundingBankAdminService.buildFundingBankFormArray(
      NextObjectHelper.getPropertyFromObject(securitiesCreditLimitsValues, [DistributorProps.FUNDING_BANK])
    );

    formGroupState[SCLProps.SECURITIES] = this._securitiesAdminService.buildSecuritiesFormGroup(
      securitiesCreditLimitsValues[SCLProps.SECURITIES]
    );

    const distributorFormGroup = this._fb.group(formGroupState);
    // TODO Activate
    /*
    const securitiesControl = distributorFormGroup.get(SCLProps.SECURITIES);
    securitiesControl?.setValidators(
      NextInputValidators.securitiesAmount(distributorFormGroup.get([SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL])!)
    );*/

    return distributorFormGroup;
  }

  private _buildSCLBaseValues() {
    const groupProps = [SCLProps.BELONGS_TO_GROUP];

    const formConfig: FormConfig = {
      [SCLProps.BELONGS_TO_GROUP]: {
        isRequired: true
      }
    };

    return { props: groupProps, config: formConfig };
  }

  private _buildSCLGroupValues(securitiesCreditLimitsValues: DSecuritiesCreditLimits) {
    const groupProps = [DistributorGroupProps.ID];

    const formConfig: FormConfig = {
      [DistributorGroupProps.ID]: {
        isRequired: securitiesCreditLimitsValues[SCLProps.BELONGS_TO_GROUP]
      }
    };

    return { props: groupProps, config: formConfig };
  }

  private _buildSCLGroupFormGroup(
    securitiesCreditLimitsValues: DSecuritiesCreditLimits = {} as DSecuritiesCreditLimits
  ) {
    const { props, config } = this._buildSCLGroupValues(securitiesCreditLimitsValues);
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, securitiesCreditLimitsValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildProductLinesAndBlanketsGroup(productLinesAndBlankets: any, isEdit: boolean = false) {
    console.log('product lineee---',productLinesAndBlankets)
    return this._fb.group({
      [DistributorProps.PRODUCT_LINES]: this._productLinesBlanketsAdminService.buildProductLinesFormArray(
        NextObjectHelper.getPropertyFromObject(productLinesAndBlankets, [DistributorProps.PRODUCT_LINES], []),
        isEdit
      ),
      [DistributorProps.BLANKETS]: this._productLinesBlanketsAdminService.buildBlanketsFormArray(
        NextObjectHelper.getPropertyFromObject(productLinesAndBlankets, [DistributorProps.BLANKETS], []),
        isEdit
      )
    });
  }

  private _buildObservationsGroup(observations?: any) {
    const { props, config } = this._buildObservationsConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, observations, config);
    return this._fb.group(formGroupState);
  }

  private _buildObservationsConfigValues() {
    const props = [DistributorProps.OBSERVATIONS_COMMENTS];
    const config = {};

    return { props, config };
  }
}
