import { Route } from '@angular/router';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { NextRouteProps } from '../../core/enums/route.enum';
import { RoutingConfigConstant } from '../../core/constants/routing-config.constant';
import { DistributorGroupSection, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { DistributorDetailsComponent } from '@pages/distributor/pages/distributor-details/components/distributor-details/distributor-details.component';
import { dirtyFormGuard } from '@pages/distributor/guards/distributor-manager.guard';
import { DistributorFormComponent } from '@pages/distributor/pages/distributor-edition/components/distributor-form/distributor-form.component';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { DistributorGroupDetailsComponent } from '@pages/distributor/pages/distributor-details/components/distributor-group-details/distributor-group-details.component';
import { DistributorGroupFormComponent } from '@pages/distributor/pages/distributor-edition/components/distributor-group-form/distributor-group-form.component';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';

export class DistributorRoutingConfig {
  static distributorRoute: Route = {
    path: DistributorManagerRouteConfig.distributorConfig[NextRouteProps.PATH],
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorConfig
    },
    children: [
      {
        path: '',
        redirectTo: `${RoutingConfigConstant.newParam}`,
        pathMatch: 'full'
      },
      {
        path: DistributorManagerRouteConfig.distributorDetailConfig[NextRouteProps.PATH],
        data: {
          [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorDetailConfig
        },
        children: [
          {
            path: DistributorManagerRouteConfig.distributorDetail[NextRouteProps.PATH],
            data: {
              [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorDetail
            },
            children: [
              {
                path: '',
                redirectTo: `${DistributorConstant.formSectionConfig[DistributorSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
                pathMatch: 'full'
              },
              {
                path: DistributorManagerRouteConfig.distributorDetailTabStep[NextRouteProps.PATH],
                component: DistributorDetailsComponent,
                data: {
                  [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorDetailTabStep,
                  [NextRouteProps.FORM_SECTION_CONFIG]: DistributorConstant.formSectionConfig
                }
              }
            ]
          }
        ]
      },
      {
        path: DistributorManagerRouteConfig.distributorFormId[NextRouteProps.PATH],
        data: { [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorFormId },
        children: [
          {
            path: '',
            redirectTo: `${DistributorConstant.formSectionConfig[DistributorSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
            pathMatch: 'full'
          },
          {
            path: DistributorManagerRouteConfig.distributorFormStep[NextRouteProps.PATH],
            canDeactivate: [dirtyFormGuard],
            component: DistributorFormComponent,
            data: {
              [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.distributorFormStep,
              [NextRouteProps.FORM_SECTION_CONFIG]: DistributorConstant.formSectionConfig
            }
          }
        ]
      }
    ]
  };

  static readonly distributorGroupRoute: Route = {
    path: DistributorManagerRouteConfig.groupForm[NextRouteProps.PATH],
    data: {
      [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupForm
    },
    children: [
      {
        path: '',
        redirectTo: `${RoutingConfigConstant.newParam}`,
        pathMatch: 'full'
      },
      {
        path: DistributorManagerRouteConfig.groupDetailConfig[NextRouteProps.PATH],
        data: {
          [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupDetailConfig
        },
        children: [
          {
            path: DistributorManagerRouteConfig.groupDetail[NextRouteProps.PATH],
            data: {
              [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupDetail
            },
            children: [
              {
                path: '',
                redirectTo: `${DistributorGroupConstant.formSectionConfig[DistributorGroupSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
                pathMatch: 'full'
              },
              {
                path: DistributorManagerRouteConfig.groupDetailTabStep[NextRouteProps.PATH],
                component: DistributorGroupDetailsComponent,
                data: {
                  [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupDetailTabStep,
                  [NextRouteProps.FORM_SECTION_CONFIG]: DistributorGroupConstant.formSectionConfig
                }
              }
            ]
          }
        ]
      },
      {
        path: DistributorManagerRouteConfig.groupFormId[NextRouteProps.PATH],
        data: { [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupFormId },
        children: [
          {
            path: '',
            redirectTo: `${DistributorGroupConstant.formSectionConfig[DistributorGroupSection.GENERAL_INFORMATION][FormSectionProps.PATH]}`,
            pathMatch: 'full'
          },
          {
            path: DistributorManagerRouteConfig.groupFormStep[NextRouteProps.PATH],
            canDeactivate: [dirtyFormGuard],
            component: DistributorGroupFormComponent,
            data: {
              [NextRouteProps.ROUTE_ITEM_CONFIG]: DistributorManagerRouteConfig.groupFormStep,
              [NextRouteProps.FORM_SECTION_CONFIG]: DistributorGroupConstant.formSectionConfig
            }
          }
        ]
      }
    ]
  };
}
