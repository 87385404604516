import { CurrencyConstantMock } from './currency.constant.mock';

export class CurrencyEndpointsConstant {
  static config = {
    LIST: {
      url: 'CURENCY',
      mock: CurrencyConstantMock.list
    }
  };
}
