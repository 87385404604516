import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DistributorGroupSection, DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { FormConfig } from '@utils/models/Form';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { SecuritiesAdminService } from '@pages/distributor/components/securities-form/securities-admin.service';
import { FundingBankAdminService } from '@pages/distributor/services/funding-bank-admin.service';
import { NextInputValidators } from '../../../shared/validators/input.validator';
import { PatternConstant } from '../../../core/constants/pattern.constant';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DGSecuritiesCreditLimits, DistributorGroupType } from '@pages/distributor/types/distributor-group.type';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { AddressProps } from '../../../core/enums/address.enum';
import { ContactProps } from '../../../core/enums/contact.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { GenericObject } from '@utils/models/Types';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { CreditAdminService } from '@pages/distributor/services/credit-admin.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorGroupAdminService {
  constructor(
    private _fb: FormBuilder,
    private _securitiesAdminService: SecuritiesAdminService,
    private _fundingBankAdminService: FundingBankAdminService,
    private _creditAdminService: CreditAdminService
  ) {}

  buildDistributorGroupFormGroup(distributorGroup?: DistributorGroupType) {
    const mainGroupValues = {
      [DistributorGroupSection.GENERAL_INFORMATION]: this._buildGeneralInformationGroup(
        NextObjectHelper.getPropertyFromObject(distributorGroup, [DistributorGroupSection.GENERAL_INFORMATION])
      ),
      [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]: this._buildSecuritiesCreditLimit(
        NextObjectHelper.getPropertyFromObject(distributorGroup, [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS])
      ),
      [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]: this.buildDistributorCreditLimitsFormArray(
        NextObjectHelper.getPropertyFromObject(
          distributorGroup,
          [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS],
          []
        )
      )
    };
    return this._fb.group(mainGroupValues);
  }

  buildDistributorCreditLimitsFormArray(distributorList: any = [], isDisabled: boolean = false) {
    const group = distributorList.map((distributor: any) => {
      return this._buildDistributorCreditLimitsFormGroup(distributor, isDisabled);
    });
    const formArray = this._fb.array(group);

    if (!isDisabled) {
      formArray.addValidators(
        NextInputValidators.amountListEqualsToReferenceControl(
          [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL],
          DistributorGroupProps.DISTRIBUTOR_CREDIT_LIMIT_AMOUNT,
          'DISTRIBUTOR.MANAGER.DETAILS.TABS.DISTRIBUTOR_CREDIT_LIMITS.TITLES.DISTRIBUTOR_CREDIT_LIMITS',
          'DISTRIBUTOR.MANAGER.DETAILS.TABS.DISTRIBUTOR_CREDIT_LIMITS.FIELDS.TOTAL_GROUP_CREDIT_LIMIT'
        )
      );
    }

    return formArray;
  }

  private _buildGeneralInformationGroup(generalInformationValues = {}) {
    const { props, config } = this._buildGeneralInformationConfigValues();
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, generalInformationValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildGeneralInformationConfigValues() {
    const groupProps = [
      DistributorGroupProps.ID,
      DistributorGroupProps.NAME,
      DistributorGroupProps.GROUP_OPERATES_WITH_SWING_LINES,
      DistributorGroupProps.COUNTRY,
      DistributorGroupProps.COUNTRY_MEMBERSHIP,
      DistributorGroupProps.ID_NUMBER,
      DistributorGroupProps.CREATION_DATE,
      DistributorGroupProps.VAT_NUMBER,
      DistributorGroupProps.ADDRESS,
      DistributorGroupProps.TOWN,
      DistributorGroupProps.POSTAL_CODE,
      DistributorGroupProps.CONTACT_NAME,
      DistributorGroupProps.WORK_TELEPHONE,
      DistributorGroupProps.DELETION_SUSPENSION_ORDER
    ];

    const formConfig: FormConfig = {
      [DistributorGroupProps.ID]: {
        [FormConfigProps.IS_DISABLED]: true
      },
      [DistributorGroupProps.NAME]: {
        isRequired: true,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)]
      },
      [DistributorGroupProps.GROUP_OPERATES_WITH_SWING_LINES]: {
        isRequired: true
      },
      [DistributorGroupProps.COUNTRY]: {
        isRequired: true,
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.COUNTRY, CountryProps.CODE]
      },
      [DistributorGroupProps.COUNTRY_MEMBERSHIP]: {
        isRequired: true,
        isDisabled: true,
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.COUNTRY, CountryProps.MEMBERSHIP]
      },
      [DistributorGroupProps.ID_NUMBER]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)]
      },
      [DistributorGroupProps.VAT_NUMBER]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)]
      },
      [DistributorGroupProps.ADDRESS]: {
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.ADDRESS, AddressProps.ADDRESS]
      },
      [DistributorGroupProps.POSTAL_CODE]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.ADDRESS, AddressProps.POSTAL_CODE]
      },
      [DistributorGroupProps.TOWN]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.ADDRESS, AddressProps.TOWN]
      },
      [DistributorGroupProps.WORK_TELEPHONE]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.CONTACT, ContactProps.PHONE]
      },
      [DistributorGroupProps.CONTACT_NAME]: {
        isRequired: false,
        validators: [NextInputValidators.patternValidation(PatternConstant.alphaNumericPatternWithSpecialChars)],
        [FormConfigProps.ENTITY_PATH]: [DistributorGroupProps.CONTACT, ContactProps.NAME]
      },
      [DistributorGroupProps.DELETION_SUSPENSION_ORDER]: {
        isRequired: true
      }
    };

    return { props: groupProps, config: formConfig };
  }

  private _buildSecuritiesCreditLimit(
    securitiesCreditLimitsValues: DGSecuritiesCreditLimits = {} as DGSecuritiesCreditLimits
  ) {
    const formGroupState: GenericObject = {};

    formGroupState[SCLProps.CREDIT] = this._creditAdminService.buildCreditLimitsFormGroup(
      securitiesCreditLimitsValues,
      true
    );
    formGroupState[SCLProps.CREDIT_BANKS] = this._fundingBankAdminService.buildFundingBankFormArray(
      securitiesCreditLimitsValues[SCLProps.CREDIT_BANKS]
    );
    formGroupState[SCLProps.SECURITIES] = this._securitiesAdminService.buildSecuritiesFormGroup(
      securitiesCreditLimitsValues[SCLProps.SECURITIES]
    );

    const formGroup = this._fb.group(formGroupState);
    // TODO Activate
    /*
    const securitiesControl = formGroup.get(SCLProps.SECURITIES);
    securitiesControl?.setValidators(
      NextInputValidators.securitiesAmount(formGroup.get([SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL])!)
    );*/

    return formGroup;
  }

  private _buildDistributorCreditLimitsFormGroup(distributorValues = {}, isDisabled: boolean = false) {
    const { props, config } = this._buildGroupDistributorConfigValues(isDisabled);
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, distributorValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildGroupDistributorConfigValues(isDisabled: boolean = false) {
    const groupProps = [
      DistributorProps.ID,
      DistributorProps.NAME,
      DistributorProps.CREDIT_LIMIT_AMOUNT
      // DistributorProps.CURRENCY // TODO Add Currency
    ];
    const formConfig: FormConfig = {
      [DistributorProps.CREDIT_LIMIT_AMOUNT]: {
        isDisabled: isDisabled
      }
    };

    return { props: groupProps, config: formConfig };
  }
}
