<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.NAME] as groupNameControl"
                   [control]="groupNameControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.NAME'"
                   [isFourEye]="true"
                   slot1
  >
    <next-input [control]="groupNameControl"
                [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.NAME]"
                [testIdDataAttr]="DistributorGroupProps.NAME" inputElement></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorGroupProps.GROUP_OPERATES_WITH_SWING_LINES] as groupOperatesWithSwingLinesControl"
    [control]="groupOperatesWithSwingLinesControl"
    [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.GROUP_OPERATES_WITH_SWING_LINES'" [isFourEye]="true" slot1>
    <next-radio [isBinaryOptions]="true" [control]="groupOperatesWithSwingLinesControl"
                [testIdDataAttr]="DistributorGroupProps.GROUP_OPERATES_WITH_SWING_LINES" inputElement></next-radio>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.COUNTRY] as countryControl"
                   [control]="countryControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.COUNTRY'" slot1>
    <next-dropdown [control]="countryControl" [itemsObs$]="countryList$"
                   [name]="DistributorManagerFilterProps.RISK_CLASS" [testIdDataAttr]="DistributorGroupProps.COUNTRY"
                   inputElement></next-dropdown>
  </input-container>
</column-pair>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorGroupProps.COUNTRY_MEMBERSHIP] as countryMembershipControl"
    [control]="countryMembershipControl"
    [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.COUNTRY_MEMBERSHIP'" slot1>
    <next-input [control]="countryMembershipControl" [testIdDataAttr]="DistributorGroupProps.COUNTRY_MEMBERSHIP"
                inputElement></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.ID_NUMBER] as idNumberControl"
                   [control]="idNumberControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.ID_NUMBER'"
                   slot1
  >
    <next-input [control]="idNumberControl"
                [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.ID_NUMBER]"
                [testIdDataAttr]="DistributorGroupProps.ID_NUMBER"
                inputElement></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.CREATION_DATE] as creationDateControl"
                   [control]="creationDateControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.CREATION_DATE'" slot1>
    <next-calendar [control]="creationDateControl" [maxDate]="currentDate"
                   [testIdDataAttr]="DistributorGroupProps.CREATION_DATE"
                   inputElement></next-calendar>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.VAT_NUMBER] as vatNumberControl"
                   [control]="vatNumberControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.VAT_NUMBER'" slot1>
    <next-input [control]="vatNumberControl"
                [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.VAT_NUMBER]"
                [testIdDataAttr]="DistributorGroupProps.VAT_NUMBER"
                inputElement></next-input>
  </input-container>
</column-pair>
<p-divider></p-divider>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.ADDRESS] as companyAddressControl"
                   [control]="companyAddressControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.COMPANY_ADDRESS'" slot1>
    <next-input [control]="companyAddressControl" [testIdDataAttr]="DistributorGroupProps.ADDRESS"
                inputElement></next-input>
  </input-container>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.TOWN] as townControl"
                   [control]="townControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.TOWN'" slot2>
    <next-input [control]="townControl" [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.TOWN]"
                [testIdDataAttr]="DistributorGroupProps.TOWN" inputElement></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.POSTAL_CODE] as postalCodeControl"
                   [control]="postalCodeControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.POSTAL_CODE'" slot1>
    <next-input [control]="postalCodeControl"
                [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.POSTAL_CODE]"
                [testIdDataAttr]="DistributorGroupProps.POSTAL_CODE"
                inputElement></next-input>
  </input-container>
</column-pair>
<column-pair>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.CONTACT_NAME] as contactNameControl"
                   [control]="contactNameControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.CONTACT_NAME'" slot1>
    <next-input [control]="contactNameControl" [testIdDataAttr]="DistributorGroupProps.CONTACT_NAME"
                inputElement></next-input>
  </input-container>
  <input-container *ngIf="formGroup | deepControl: [DistributorGroupProps.WORK_TELEPHONE] as workTelephoneControl"
                   [control]="workTelephoneControl"
                   [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.WORK_TELEPHONE'" slot2>
    <next-input [control]="workTelephoneControl"
                [maxlength]="DistributorGroupConstant.formMaxLength[DistributorGroupProps.WORK_TELEPHONE]"
                [testIdDataAttr]="DistributorGroupProps.WORK_TELEPHONE"
                inputElement></next-input>
  </input-container>
</column-pair>
<p-divider></p-divider>
<column-pair>
  <input-container
    *ngIf="formGroup | deepControl: [DistributorGroupProps.DELETION_SUSPENSION_ORDER] as deletionSuspensionOrderControl"
    [control]="deletionSuspensionOrderControl"
    [labelKey]="'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.DELETION_SUSPENSION_ORDER'" slot1>
    <next-radio [isBinaryOptions]="true" [control]="deletionSuspensionOrderControl"
                [testIdDataAttr]="DistributorGroupProps.DELETION_SUSPENSION_ORDER" inputElement></next-radio>
  </input-container>
</column-pair>
