<next-card [ngClass]="'securities-form__card'">
  <header-detail
    [header]="
      ('SECURITIES' + (securityType === SecurityType.INSURERS ? '.INSURER_TITLE' : '.GUARANTOR_TITLE')) | translate
    "
    headerTemplate
  ></header-detail>
  <ng-container *ngIf="formArray">
    <ng-container *ngFor="let groupItem of formArray.controls; first as first; let idx = index">
      <column-pair>
        <input-container
          *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.TYPE :  GuarantorProps.TYPE
] as entityTypeControl"
          [control]="entityTypeControl"
          [labelKey]="'SECURITIES' + '.ENTITY_TYPE'"
          slot1
        >
          <next-dropdown
            [control]="entityTypeControl"
            [items]="entityTypeList"
            [name]="securityType === SecurityType.INSURERS ? InsurerProps.TYPE :  GuarantorProps.TYPE"
            [testIdDataAttr]="securityType === SecurityType.INSURERS ? InsurerProps.TYPE :  GuarantorProps.TYPE"
            inputElement
          ></next-dropdown>
        </input-container>
        <div class="securities-form__remove" slot2>

          <link-button [iconType]="NextIcon.DELETE"
                       [isDisabled]="formArray.controls.length === 1"
                       (clickEvt)="handleRemoveSecurity(idx)" ></link-button>
        </div>
      </column-pair>
      <div
        *ngIf="groupItem | deepControlValue: [securityType === SecurityType.INSURERS ? InsurerProps.TYPE :  GuarantorProps.TYPE] | async"
        class="securities-form__container"
      >
        <next-card [hasBackgroundColor]="true">
          <column-pair>
            <input-container
              *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.NAME :  GuarantorProps.NAME] as entityNameControl"
              [control]="entityNameControl"
              [labelKey]="
                'SECURITIES' +
                ((groupItem | deepControlValue: [securityType === SecurityType.INSURERS ? InsurerProps.TYPE :  GuarantorProps.TYPE] | async) === SecurityEntity.FDI
                  ? '.INSURER_NAME'
                  : '.GUARANTOR_NAME')
              "
              slot1
            >
              <next-dropdown
                [control]="entityNameControl"
                [itemsObs$]="securityBankList$"
                [name]="securityType === SecurityType.INSURERS ? InsurerProps.NAME :  GuarantorProps.TYPE"
                [testIdDataAttr]="securityType === SecurityType.INSURERS ? InsurerProps.NAME :  GuarantorProps.NAME"
                inputElement
              ></next-dropdown>
            </input-container>
            <input-container
              *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.STATUS :  GuarantorProps.STATUS] as securityStatusControl"
              [control]="securityStatusControl"
              [labelKey]="'SECURITIES' + '.SECURITY_STATUS'"
              slot2
            >
              <next-dropdown
                [control]="securityStatusControl"
                [itemsObs$]="securityStatusList$"
                [name]="GuarantorProps.STATUS"
                [testIdDataAttr]="securityType === SecurityType.INSURERS ? InsurerProps.STATUS :  GuarantorProps.STATUS"
                inputElement
              ></next-dropdown>
            </input-container>
          </column-pair>
          <column-pair>
            <input-container
              *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.CURRENCY :  GuarantorProps.CURRENCY] as securityCurrencyControl"
              [control]="securityCurrencyControl"
              [labelKey]="'SECURITIES' + '.SECURITY_CURRENCY'"
              slot1
            >
              <next-dropdown
                [control]="securityCurrencyControl"
                [itemsObs$]="securityCurrencyList$"
                [name]="securityType === SecurityType.INSURERS ? InsurerProps.CURRENCY :  GuarantorProps.CURRENCY"
                [testIdDataAttr]="securityType === SecurityType.INSURERS ? InsurerProps.CURRENCY :  GuarantorProps.CURRENCY"
                inputElement
              ></next-dropdown>
            </input-container>
            <input-container
              *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.AMOUNT :  GuarantorProps.AMOUNT] as securityAmountControl"
              [control]="securityAmountControl"
              [labelKey]="'SECURITIES' + '.SECURITY_AMOUNT'"
              slot2
            >
              <next-input-number
                [control]="securityAmountControl"
                [type]="InputNumberType.AMOUNT"
                inputElement
              ></next-input-number>
            </input-container>
          </column-pair>
          <column-pair>
            <input-container
              *ngIf="groupItem | deepControl: [securityType === SecurityType.INSURERS ? InsurerProps.EFFECTIVE_DATE :  GuarantorProps.EFFECTIVE_DATE] as effectiveDateControl"
              [control]="effectiveDateControl"
              [labelKey]="'SECURITIES' + '.EFFECTIVE_DATE'"
              slot1
            >
              <next-calendar
                [control]="effectiveDateControl"
                [testIdDataAttr]="securityType === SecurityType.INSURERS ? InsurerProps.EFFECTIVE_DATE :  GuarantorProps.EFFECTIVE_DATE"
                inputElement
              ></next-calendar>
            </input-container>
            <input-container
              *ngIf="groupItem | deepControl: [GuarantorProps.EXPIRY_DATE] as expiryDateControl"
              [control]="expiryDateControl"
              [labelKey]="'SECURITIES' + '.EXPIRY_DATE'"
              slot2
            >
              <next-calendar
                [control]="expiryDateControl"
                [testIdDataAttr]="GuarantorProps.EXPIRY_DATE"
                inputElement
              ></next-calendar>
            </input-container>
          </column-pair>
          <ng-container
            *ngIf="SecurityType.INSURERS && (groupItem | deepControlValue: [InsurerProps.TYPE] | async) === SecurityEntity.FDI"
          >
            <column-pair>
              <input-container
                *ngIf="groupItem | deepControl: [InsurerProps.COUNTRY_RISK_DEBTOR] as riskDebtorControl"
                [control]="riskDebtorControl"
                [labelKey]="'SECURITIES' + '.COUNTRY_RISK_DEBTOR'"
                slot1
              >
                <next-dropdown
                  [control]="riskDebtorControl"
                  [itemsObs$]="riskDebtorList$"
                  [name]="InsurerProps.COUNTRY_RISK_DEBTOR"
                  [testIdDataAttr]="InsurerProps.COUNTRY_RISK_DEBTOR"
                  inputElement
                ></next-dropdown>
              </input-container>
            </column-pair>
            <column-pair>
              <input-container
                *ngIf="groupItem | deepControl: [InsurerProps.COUNTRY_RISK_RATING] as countryRiskControl"
                [control]="countryRiskControl"
                [labelKey]="'SECURITIES' + '.INSURANCE_COUNTRY_RISK'"
                slot1
              >
                <next-dropdown
                  [control]="countryRiskControl"
                  [itemsObs$]="insuranceCountryRiskList$"
                  [name]="InsurerProps.COUNTRY_RISK_RATING"
                  [testIdDataAttr]="InsurerProps.COUNTRY_RISK_RATING"
                  inputElement
                ></next-dropdown>
              </input-container>
            </column-pair>
          </ng-container>
        </next-card>
      </div>
    </ng-container>
  </ng-container>
  <div class="securities-form__add-new">
    <link-button
      [label]="'SECURITIES' + '.ADD_NEW_SECURITY' | translate"
      [iconType]="NextIcon.PLUS"
      [iconPosition]="LinkButtonPosition.LEFT"
      (clickEvt)="handleAddSecurity()"
    ></link-button>
  </div>
</next-card>
