<ng-container *ngIf="(this.details$ | async) as details">
  <next-action-header
    [label]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.GENERAL_INFORMATION, DistributorProps.NAME]">
    <ng-container *ngIf="isTaskBoard" actionContent>
      <review-modified-fields
        [updateData]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR]"
        [originalData]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF]"
        [dataMapper]="DistributorConstant.compareDataMapper"
        [section]="section"
      ></review-modified-fields>
    </ng-container>
  </next-action-header>
  <general-details
    [header]="(headerDistributor) | translate: {id}"
    [dataTitleConfig]="generalDetailsMap"
    [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR]"
    [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF]"
    [status]="details | safeGuardObjectProperty: [
    DistributorApiProps.DISTRIBUTOR,
    DistributorSection.GENERAL_INFORMATION,
    DistributorProps.ACCOUNT_STATUS
  ]"></general-details>

  <p-tabView [activeIndex]="sectionList.indexOf(activeSectionSig())" (activeIndexChange)="handleChangeTab($event)">
    <p-tabPanel [header]="(TITLE_TABS_PREFIX + '.GENERAL_INFORMATION.NAME') | translate">
      <distributor-details-general-information
        [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.GENERAL_INFORMATION]"
        [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF, DistributorSection.GENERAL_INFORMATION]"
        [isGroup]="isGroup"></distributor-details-general-information>
    </p-tabPanel>
    <p-tabPanel [header]="(TITLE_TABS_PREFIX + '.SECURITIES_AND_CREDIT_LIMITS.NAME') | translate">
      <distributor-details-securities-credit-limits
        [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.SECURITIES_AND_CREDIT_LIMITS]"
        [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF, DistributorSection.SECURITIES_AND_CREDIT_LIMITS]"
        [isGroup]="isGroup"></distributor-details-securities-credit-limits>
    </p-tabPanel>
    <p-tabPanel [header]="(TITLE_TABS_PREFIX + '.PRODUCT_LINES_AND_BLANKETS.NAME') | translate">
      <product-lines-and-blankets
        [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.PRODUCT_LINES_AND_BLANKETS]"
        [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF, DistributorSection.PRODUCT_LINES_AND_BLANKETS]"
      ></product-lines-and-blankets>
    </p-tabPanel>
    <p-tabPanel [header]="(TITLE_TABS_PREFIX + '.SOURCE_LOCATION_MAPPING.NAME') | translate">
      <source-location-mapping
        [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.SOURCE_LOCATION_MAPPING]"
        [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF, DistributorSection.SOURCE_LOCATION_MAPPING]"></source-location-mapping>
    </p-tabPanel>
    <p-tabPanel [header]="(TITLE_TABS_PREFIX + '.OBSERVATIONS.NAME') | translate">
      <distributor-observations
        [data]="details | safeGuardObjectProperty: [DistributorApiProps.DISTRIBUTOR, DistributorSection.OBSERVATIONS]"
        [dataRef]="details | safeGuardObjectProperty: [TbDistributorApiProps.DISTRIBUTOR_REF, DistributorSection.OBSERVATIONS]"
      ></distributor-observations>
    </p-tabPanel>
  </p-tabView>
</ng-container>
