<next-card *ngIf="distributorManagerType === DistributorManagerType.DISTRIBUTOR" [hasBorder]="true"
           [ngClass]="'group-securities-limits__card'">
  <header-detail
    [header]="LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.TITLE' | translate"
    headerTemplate
  ></header-detail>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.BELONGS_TO_GROUP] as accountStatusControl"
      [control]="accountStatusControl"
      [labelKey]="LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.BELONGS_TO_GROUP'"
      slot1
    >
      <next-radio
        [isBinaryOptions]="true"
        [control]="accountStatusControl"
        inputElement
      ></next-radio>
    </input-container>
    <ng-container *ngIf="formGroup | deepControlValue: [SCLProps.BELONGS_TO_GROUP] | async" slot2>
      <input-container
        *ngIf="formGroup | deepControl: [SCLProps.GROUP, DistributorGroupProps.ID] as distributorGroupControl"
        [control]="distributorGroupControl"
        [labelKey]="LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.SELECT_GROUP'"
      >
        <next-dropdown
          [control]="distributorGroupControl"
          [itemsObs$]="distributorGroupList$"
          [name]="SCLProps.GROUP"
          [testIdDataAttr]="SCLProps.GROUP"
          inputElement
        ></next-dropdown>
      </input-container>
    </ng-container>
  </column-pair>
  <ng-container *ngIf="formGroup | deepControlValue: [SCLProps.BELONGS_TO_GROUP] | async">
    <ng-container *ngIf="groupData">
      <div class="group-securities-limits__container">
        <data-card
          [dataObject]="groupData"
          [config]="{
            titleKey: LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.TOTAL_GROUP_CREDIT_LIMIT',
            path: [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL],
            pipeList: [
              {
                pipe: NextFormatCurrencyPipe,
                args: [
                  {
                    fromValue: [
                      DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
                      SCLProps.CREDIT,
                      CreditProps.CURRENCY
                    ]
                  }
                ]
              }
            ]
          }"
          class="group-securities-limits__item"
        ></data-card>
      </div>
      <div class="group-securities-limits__container">
        <data-card
          [dataObject]="groupData"
          [config]="{
            titleKey: LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.BANK_GUARANTEE',
            path: [
              DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
              SCLProps.CREDIT,
              CreditProps.BANK_GUARANTEED_CAPITAL
            ],
            pipeList: [
              {
                pipe: NextFormatCurrencyPipe,
                args: [
                  {
                    fromValue: [
                      DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
                      SCLProps.CREDIT,
                      CreditProps.CURRENCY
                    ]
                  }
                ]
              }
            ]
          }"
          class="group-securities-limits__item"
        ></data-card>
        <data-card
          [dataObject]="groupData"
          [config]="{
            titleKey: LABEL_KEY_PREFIX + '.GROUP_SECURITIES_AND_CREDIT_LIMITS.FD_INSURANCE',
            path: [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, SCLProps.CREDIT, CreditProps.FDI],
            pipeList: [
              {
                pipe: NextFormatCurrencyPipe,
                args: [
                  {
                    fromValue: [
                      DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
                      SCLProps.CREDIT,
                      CreditProps.CURRENCY
                    ]
                  }
                ]
              }
            ]
          }"
          class="group-securities-limits__item"
        ></data-card>
      </div>

      <next-table
        *ngIf="
          groupData | safeGuardObjectProperty: [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS] as distributorList
        "
        [data]="distributorList"
        [columns]="DistributorGroupConstant.distributorLimitsTable"
      ></next-table>
    </ng-container>
  </ng-container>
</next-card>
<next-card [ngClass]="'group-securities-limits__card'">
  <header-detail
    [headerKey]="
      LABEL_KEY_PREFIX +
      (distributorManagerType === DistributorManagerType.DISTRIBUTOR
        ? '.DISTRIBUTOR_CREDIT_LIMIT.FORM_TITLE'
        : '.DISTRIBUTOR_CREDIT_LIMIT.GROUP_FORM_TITLE')
    "
    headerTemplate
  ></header-detail>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL] as accountStatusControl"
      [control]="accountStatusControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.CREDIT_LIMIT_AMOUNT'"
      slot1
    >
      <next-input-number
        [control]="accountStatusControl"
        [type]="InputNumberType.AMOUNT"
        inputElement
      ></next-input-number>
    </input-container>
  </column-pair>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.CURRENCY] as currencyControl"
      [control]="currencyControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.CURRENCY'"
      slot1
    >
      <next-dropdown [itemsObs$]="currencyList$" [control]="currencyControl" inputElement></next-dropdown>
    </input-container>
  </column-pair>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.EFFECTIVE_DATE] as effectiveDateControl"
      [control]="effectiveDateControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.EFFECTIVE_DATE'"
      slot1
    >
      <next-calendar [control]="effectiveDateControl" inputElement></next-calendar>
    </input-container>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.EXPIRY_DATE] as expiryDateControl"
      [control]="expiryDateControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.EXPIRY_DATE'"
      slot2
    >
      <next-calendar
        [control]="expiryDateControl"
        [minDate]="formGroup | deepControlValue: [SCLProps.CREDIT, CreditProps.PACK_APPROVAL_DATE] | async | dateObject"
        inputElement
      ></next-calendar>
    </input-container>
  </column-pair>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.PACK_APPROVAL_DATE] as packApprovalDateControl"
      [control]="packApprovalDateControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.PACK_APPROVAL_DATE'"
      slot1
    >
      <next-calendar [control]="packApprovalDateControl" inputElement></next-calendar>
    </input-container>
  </column-pair>
  <column-pair>
    <input-container
      *ngIf="formGroup | deepControl: [SCLProps.CREDIT, CreditProps.STATUS] as creditLimitStatusControl"
      [control]="creditLimitStatusControl"
      [labelKey]="LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.CREDIT_LIMIT_STATUS'"
      slot1
    >
      <next-dropdown [itemsObs$]="creditLimitStatus$" [control]="creditLimitStatusControl" inputElement></next-dropdown>
    </input-container>
  </column-pair>
  <!-- multiple -->

  <funding-bank-form
    [distributorManagerType]="distributorManagerType"
    [creditLimit]="formGroup | deepControlValue: [SCLProps.CREDIT, CreditProps.AVAILABLE_CAPITAL] | async"
    [formArray]="formGroup | deepControl: [SCLProps.CREDIT_BANKS]"
  ></funding-bank-form>
</next-card>

<next-card [headerKey]="'SECURITIES.TITLE'">
  <ng-container
    *ngIf="formGroup | deepControl: [SCLProps.SECURITIES] as securitiesControl">
    <securities-form
      [securityType]="SecurityType.INSURERS"
      [distributorManagerType]="distributorManagerType"
      [creditLimit]="formGroup | deepControlValue: [CreditProps.AVAILABLE_CAPITAL] | async"
      [formArray]="securitiesControl | deepControl: [SecurityType.INSURERS]"
    ></securities-form>

    <securities-form
      [securityType]="SecurityType.GUARANTORS"
      [distributorManagerType]="distributorManagerType"
      [creditLimit]="formGroup | deepControlValue: [CreditProps.AVAILABLE_CAPITAL] | async"
      [formArray]="securitiesControl | deepControl: [SecurityType.GUARANTORS]"
    ></securities-form>

    <next-message
      *ngIf="securitiesControl.errors | safeGuardObjectProperty: [NextInputValidationKey.REQUIRED_SECURITIES]"
      [messages]="[
      { severity: 'warn', summary: LABEL_KEY_PREFIX + '.DISTRIBUTOR_CREDIT_LIMIT.SECURITIES_TOTAL_WARN' | translate }
    ]"
    ></next-message>
  </ng-container>
</next-card>
