import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DSecuritiesCreditLimits } from '@pages/distributor/types/distributor.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { FormConfig } from '@utils/models/Form';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { DGSecuritiesCreditLimits } from '@pages/distributor/types/distributor-group.type';

@Injectable({
  providedIn: 'root'
})
export class CreditAdminService {
  constructor(private _fb: FormBuilder) {}

  buildCreditLimitsFormGroup(
    securitiesCreditLimitsValues?: DSecuritiesCreditLimits | DGSecuritiesCreditLimits,
    isGroup?: boolean
  ) {
    const creditValues = NextObjectHelper.getPropertyFromObject(securitiesCreditLimitsValues, [SCLProps.CREDIT], {});
    const { props, config } = this._buildCreditLimitValues(creditValues, isGroup);
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, creditValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildCreditLimitValues(
    securitiesCreditLimitsValues: DSecuritiesCreditLimits = {} as DSecuritiesCreditLimits,
    isGroup?: boolean
  ) {
    const belongsToGroup = !!NextObjectHelper.getPropertyFromObject(securitiesCreditLimitsValues, [
      SCLProps.BELONGS_TO_GROUP
    ]);
    const requiredBelongingToGroup = !isGroup && !belongsToGroup;
    const disabledBelongingToGroup = !isGroup && belongsToGroup;

    const groupProps = [
      CreditProps.AVAILABLE_CAPITAL, // Credit Limit Amount ?
      CreditProps.CURRENCY,
      CreditProps.EFFECTIVE_DATE,
      CreditProps.EXPIRY_DATE,
      CreditProps.PACK_APPROVAL_DATE,
      CreditProps.STATUS
    ];

    const formConfig: FormConfig = {
      [CreditProps.AVAILABLE_CAPITAL]: {
        isRequired: true
      },
      [CreditProps.CURRENCY]: {
        isRequired: true
      },
      [CreditProps.EFFECTIVE_DATE]: {
        isRequired: requiredBelongingToGroup || isGroup,
        [FormConfigProps.IS_DISABLED]: disabledBelongingToGroup
      },
      [CreditProps.EXPIRY_DATE]: {
        isRequired: requiredBelongingToGroup || isGroup,
        [FormConfigProps.IS_DISABLED]: disabledBelongingToGroup
      },
      [CreditProps.PACK_APPROVAL_DATE]: {
        isRequired: requiredBelongingToGroup || isGroup,
        [FormConfigProps.IS_DISABLED]: disabledBelongingToGroup
      },
      [CreditProps.STATUS]: {
        isRequired: true
      }
    };

    return { props: groupProps, config: formConfig };
  }
}
