import { Injectable } from '@angular/core';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { CountryEndpointsConstant } from '../constants/country.endpoints.constant';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private _dropdownService: DropdownService) {}

  getDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      CountryEndpointsConstant.config.LIST,
      {
        labelProp: CountryProps.NAME,
        valueProp: CountryProps.CODE
      },
      { resultWithinProp: ['result', 'countries'] }
    );
  }
}
