import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { DistributorGroupAdminService } from '@pages/distributor/services/distributor-group-admin.service';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { NextInputValidationKey } from '@utils/enums/validators.enum';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';

@Component({
  selector: 'distributor-credit-limits',
  templateUrl: './distributor-credit-limits.component.html',
  styleUrl: './distributor-credit-limits.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorCreditLimitsComponent extends GenericBaseComponent implements OnInit {
  @Input() groupCreditLimits: any;
  @Input() distributorCreditLimits?: any;
  @Input() formArray?: FormArray;

  distributorCreditLimitList: any;
  formArrayDataCreditLimits: FormArray;
  groupCurrency: string;
  totalGroupCreditLimit: number;
  isEdition: boolean = false;

  protected readonly DistributorGroupProps = DistributorGroupProps;
  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly TITLE_SECURITIES_PREFIX = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.DISTRIBUTOR_CREDIT_LIMITS';
  protected readonly InputNumberType = InputNumberType;

  constructor(
    private _distributorGroupAdminService: DistributorGroupAdminService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this._initializeFormConfig();
  }

  private _initializeFormConfig() {
    this.totalGroupCreditLimit = NextObjectHelper.getPropertyFromObject(this.groupCreditLimits, [
      SCLProps.CREDIT,
      CreditProps.AVAILABLE_CAPITAL
    ]);
    this.groupCurrency = NextObjectHelper.getPropertyFromObject(this.groupCreditLimits, [
      SCLProps.CREDIT,
      CreditProps.CURRENCY
    ]);

    if (this.formArray) {
      this.distributorCreditLimitList = this.formArray.getRawValue();
      this.formArrayDataCreditLimits = this.formArray;
      this.isEdition = true;
    } else {
      this.distributorCreditLimitList = this.distributorCreditLimits;

      this.formArrayDataCreditLimits = this._distributorGroupAdminService.buildDistributorCreditLimitsFormArray(
        this.distributorCreditLimits,
        !this.isEdition
      );
    }

    this.formArrayDataCreditLimits.updateValueAndValidity();
  }

  protected readonly NextInputValidationKey = NextInputValidationKey;
  protected readonly DistributorProps = DistributorProps;
}
