import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { StepsFormAbstractComponent } from '@components/organisms/steps-form-module/steps-form-abstract/steps-form-abstract.component';
import {
  DistributorManagerType,
  DistributorProps,
  DistributorSection
} from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorAdminService } from '@pages/distributor/services/distributor-admin.service';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { Distributor, DistributorApi } from '@pages/distributor/types/distributor.type';
import { DistributorService } from '@pages/distributor/services/distributor.service';
import { DistributorApiProps } from '@pages/distributor/enums/distributor.enum';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';

@Component({
  selector: 'distributor-form',
  templateUrl: './distributor-form.component.html',
  styleUrl: './distributor-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorFormComponent extends StepsFormAbstractComponent<DistributorSection> implements OnInit {
  provoId: any;
  taskId: any;
  protected readonly DistributorSection = DistributorSection;
  protected readonly FormSectionProps = FormSectionProps;
  protected readonly DistributorManagerType = DistributorManagerType;

  constructor(
    private $injector: Injector,
    private _distributorAdminService: DistributorAdminService,
    private _distributorService: DistributorService,
    private _taskBoardService: TaskBoardService
  ) {
    super($injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    this._setEntityConfig();
    this.handleEditMode();
  }

  handleEditMode() {
    if (this.isEdition) {
      if (this.isTaskBoard) {
        const taskId = this.id.replace(/\D/g, '');
        this._taskBoardService.getTask(taskId).subscribe((response: any) => {
          const payload = NextObjectHelper.getPropertyFromObject(response, ['jsonPayload']);
          let distributor;
          try {
            distributor = JSON.parse(payload);
            this._initializeForm(distributor);
          } catch (e) {
            this._toastService.error('Task Board is Corrupted');
          }
        });
      } else {
        this._distributorService.get(this.id).subscribe((response: DistributorApi) => {
          const distributor = response[DistributorApiProps.DISTRIBUTOR];
          this._initializeForm(distributor);
        });
      }
    } else {
      this._defineFormGroup();
      super.initialize();
    }
  }

  handleSaveButton() {
    this.handleSave();
  }

  // TODO Should be done in abstract
  handleNextButton() {
    if (this.activeFormControl?.pristine) {
      super.goToNextSection();
    } else {
      this.handleSave(true);
    }
  }

  // TODO Should be done in abstract
  handleSave(goToNextSection = false) {
    const formattedData = this._distributorAdminService.getFormattedDataFromSection(
      this.activeFormControl!.getRawValue(),
      this.activeSectionSig()
    );
    this._distributorService
      .saveSection(this.activeSectionSig(), formattedData, this.isEdition ? this.id : undefined)
      .subscribe(response => {
        this.provoId = NextObjectHelper.getPropertyFromObject(response.result, [
          DistributorSection.GENERAL_INFORMATION,
          DistributorProps.PROVO_ID
        ]);
        this.taskId = NextObjectHelper.getPropertyFromObject(response.result, [
          DistributorSection.GENERAL_INFORMATION,
          DistributorProps.TASK_ID
        ]);
        this.id = this.provoId;
        this.updateSection();
        this.activeFormControl!.markAsPristine();
        super.handleSaveSuccess();
        if (goToNextSection) {
          super.goToNextSection();
        }
      });
  }

  private _setEntityConfig() {
    this.formSectionList = DistributorConstant.formSectionList;
    this.formSectionConfig = DistributorConstant.formSectionConfig;
    this.redirectRouteConfig = DistributorManagerRouteConfig.mainRoute;
    this.groupFormRouteConfig = DistributorManagerRouteConfig.distributorFormStep;
  }

  private _defineFormGroup(distributor?: Distributor) {
    this.formGroup = this._distributorAdminService.buildDistributorFormGroup(distributor, true);
  }

  private _initializeForm(distributor: Distributor) {
    this._defineFormGroup(distributor);
    super.initialize();
    this._subscribeFormChanges();
  }
}
