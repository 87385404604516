import { FormDataConfigProps } from '@components/atoms/form-inputs/enums/form-data.enum';

export class FormDataConstant {
  static readonly dropdownBaseConfig = {
    labelProp: FormDataConfigProps.ENTRY_LABEL,
    valueProp: FormDataConfigProps.ENTRY_VALUE,
    hasTranslation: true
  };

  static readonly dropdownCompositeLabelConfig = {
    labelProp: FormDataConfigProps.ENTRY_VALUE,
    labelExtraProp: FormDataConfigProps.ENTRY_LABEL,
    valueProp: FormDataConfigProps.ENTRY_VALUE
  };
}
