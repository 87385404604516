import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { TabPageAbstractComponent } from '@components/organisms/tab-page-module/tab-page-abstract/tab-page-abstract.component';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { DistributorGroupSection } from '@pages/distributor/enums/distributor-manager.enum';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';
import { DistributorGroupService } from '@pages/distributor/services/distributor-group.service';
import { DistributorApiProps } from '@pages/distributor/enums/distributor.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { TbDistributorApiProps } from '@pages/task-board/enums/tb-distributors.enum';
import { Observable } from 'rxjs';
import { TbDistributorGroupApi } from '@pages/task-board/types/tb-distributors.type';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { DistributorGroupApi } from '@pages/distributor/types/distributor-group.type';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';

@Component({
  selector: 'distributor-group-details',
  templateUrl: './distributor-group-details.component.html',
  styleUrl: './distributor-group-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorGroupDetailsComponent
  extends TabPageAbstractComponent<DistributorGroupSection>
  implements OnInit
{
  @Input() isTaskBoard = false;
  isGroup = true;
  details$: Observable<DistributorGroupApi | TbDistributorGroupApi>;
  section = TaskBoardSection.DISTRIBUTORS;
  headerDistributor = 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_GROUP.ID_DISTRIBUTOR';

  protected readonly TITLE_TABS_PREFIX = 'DISTRIBUTOR.MANAGER.DETAILS.TABS';
  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly DistributorApiProps = DistributorApiProps;
  protected readonly DistributorGroupSection = DistributorGroupSection;
  protected readonly DistributorGroupProps = DistributorGroupProps;
  protected readonly TbDistributorApiProps = TbDistributorApiProps;
  protected readonly DistributorConstant = DistributorConstant;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;

  constructor(
    private $injector: Injector,
    private _distributorGroupService: DistributorGroupService,
    private _taskBoardService: TaskBoardService
  ) {
    super($injector);
  }

  ngOnInit(): void {
    this._setEntityConfig();
    if (this.isTaskBoard) {
      this.details$ = this._taskBoardService.getDistributorGroup(this.id);
    } else {
      this.details$ = this._distributorGroupService.get(this.id);
    }
    super.initialize();
  }

  private _setEntityConfig() {
    this.sectionList = DistributorGroupConstant.formSectionList;
    this.sectionConfig = DistributorGroupConstant.formSectionConfig;
  }
}
