import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { StepsFormAbstractComponent } from '@components/organisms/steps-form-module/steps-form-abstract/steps-form-abstract.component';
import { DistributorGroupSection, DistributorManagerType } from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { DistributorGroupAdminService } from '@pages/distributor/services/distributor-group-admin.service';
import { DistributorGroupService } from '@pages/distributor/services/distributor-group.service';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { DistributorGroupApi, DistributorGroupType } from '@pages/distributor/types/distributor-group.type';
import { DistributorApiProps } from '@pages/distributor/enums/distributor.enum';

@Component({
  selector: 'distributor-group-form',
  templateUrl: './distributor-group-form.component.html',
  styleUrl: './distributor-group-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorGroupFormComponent
  extends StepsFormAbstractComponent<DistributorGroupSection>
  implements OnInit
{
  protected readonly FormSectionProps = FormSectionProps;
  protected readonly DistributorGroupSection = DistributorGroupSection;
  protected readonly DistributorManagerType = DistributorManagerType;

  constructor(
    private $injector: Injector,
    private _groupAdminService: DistributorGroupAdminService,
    private _distributorGroupService: DistributorGroupService
  ) {
    super($injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    this._setEntityConfig();
    this.handleEditMode();
  }

  handleEditMode() {
    if (this.isEdition) {
      this._distributorGroupService.get(this.id).subscribe((response: DistributorGroupApi) => {
        this._defineFormGroup(response[DistributorApiProps.GROUP]);
        super.initialize();
        this._cdr.markForCheck();
      });
    } else {
      this._defineFormGroup();
      super.initialize();
    }
  }

  handleSave(goToNextSection = false) {
    const isFirstSection = this.mapSections[this.activeSectionSig()] === 0;
    return this._distributorGroupService.saveSection(isFirstSection, this.activeSectionSig()).subscribe(() => {
      this.activeFormControl?.markAsPristine();
      super.handleSaveSuccess();
      if (goToNextSection) {
        super.goToNextSection();
      }
    });
  }

  handleNextButton() {
    this.isNextFlag = true;
    if (this.activeFormControl?.pristine) {
      super.goToNextSection();
    } else {
      this.handleSave(true);
    }
  }

  private _setEntityConfig() {
    this.formSectionList = DistributorGroupConstant.formSectionList;
    this.formSectionConfig = DistributorGroupConstant.formSectionConfig;
    this.redirectRouteConfig = DistributorManagerRouteConfig.mainRoute;
    this.groupFormRouteConfig = DistributorManagerRouteConfig.groupFormStep;
  }

  private _defineFormGroup(distributorGroup?: DistributorGroupType) {
    this.formGroup = this._groupAdminService.buildDistributorGroupFormGroup(distributorGroup);
  }
}
