export enum TbDistributorFilterProps {
  CREATION_DATE_FROM = 'creationDateFrom',
  CREATION_DATE_TO = 'creationDateTo',
  REQUEST_TYPE = 'requestType'
}

export enum TbDistributorAction {
  MODIFY = 'modify',
  DELETE = 'delete'
}

export enum TbDistributorApiProps {
  DISTRIBUTOR_REF = 'distributorRef',
  GROUP_REF = 'groupRef'
}
