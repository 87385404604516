import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfig } from '@utils/models/Form';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { NextInputValidators } from '../../../shared/validators/input.validator';
import { DistributorGroupSection } from '@pages/distributor/enums/distributor-manager.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';

@Injectable({
  providedIn: 'root'
})
export class FundingBankAdminService {
  constructor(private _fb: FormBuilder) {}

  buildFundingBankFormArray(fundingBankList: any = [{}]) {
    const groups = fundingBankList.map((fundingBank: any, index: number) =>
      this.buildFundingBankFormGroup(fundingBank, index === 0)
    );
    return this._fb.array(groups, [
      NextInputValidators.amountListEqualsToReferenceControl(
        [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS, DistributorGroupProps.CREDIT_LIMIT_AMOUNT],
        FundingBankProps.FUNDING_BANK_AMOUNT,
        'FUNDING_BANK.FUNDING_BANK',
        'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_CREDIT_LIMIT.CREDIT_LIMIT_AMOUNT'
      )
    ]);
  }

  buildFundingBankFormGroup(fundingBankValues = {}, isFirst = false) {
    const { props, config } = this._buildSecuritiesConfigValues(isFirst);
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, fundingBankValues, config);
    return this._fb.group(formGroupState);
  }

  private _buildSecuritiesConfigValues(isFirst = false) {
    const groupProps = [
      FundingBankProps.CODE,
      FundingBankProps.FUNDING_BANK_AMOUNT,
      FundingBankProps.FUNDING_BANK_PERCENTAGE
    ];

    const formConfig: FormConfig = {
      [FundingBankProps.CODE]: {
        [FormConfigProps.IS_REQUIRED]: false // isFirst
      }
    };

    return { props: groupProps, config: formConfig };
  }
}
